import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NurseNavbar from '../../components/NurseNavbar';
import { getNurseProfile } from '../../api/profile';
import Loader from '../../components/Loader';
import { unsubscribeFromTopic } from '../../api/notification';
import { formatDateMY } from '../../helpers';
import Swal from "sweetalert2";

const NurseProfile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLogout = async () => {
    try {
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      // Unsubscribe from the nurse topic
      const nurseId = localStorage.getItem('nurse_id');
      if (nurseId) {
        await unsubscribeFromTopic(`nurse_${nurseId}`);
        console.log('Unsubscribed from nurse topic');
      }
      await unsubscribeFromTopic('nurse');
      await unsubscribeFromTopic('everyone');
      // Clear local storage
      localStorage.clear();
      Swal.close();
      console.log('Logged out and cleared local storage.');

      // Navigate to home page
      navigate('/');
    } catch (error) {
      Swal.close();
      console.error('Error during logout:', error);
      // You might want to show an error message to the user here
      // For now, we'll still clear local storage and navigate away
      localStorage.clear();
      navigate('/');
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const data = await getNurseProfile();
    //console.log(data);
    setProfile(data);
    setIsLoaded(true);
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <Link to="#" style={{ display: 'none' }}></Link>
            <div className="head-content">
              <div className="text-center">
                <h2>BACKLINERS - NURSING TEAM</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      {!isLoaded ? (
        <Loader isNav={true} />
      ) : !profile ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
          <h4>No Data</h4>
        </div>
      ) : (
        <>
          <section>
            <div className="custom-container">
              <div className="profile-cover">
                <img className="img-fluid profile-pic" src="/assets/images/svg/nurses_blue.svg" alt="profile" />
              </div>
              <div className="profile-name">
                <h5>{profile.name}</h5>
              </div>
            </div>
          </section>

          <section>
            <div className="custom-container">
              <ul className="profile-details-list">
                <li>
                  <h2>{profile.total_service ?? 0}</h2>
                  <h6>Total Served</h6>
                </li>
                <li>
                  <h2>{formatDateMY(profile.date_joined)}</h2>
                  <h6>Joined since</h6>
                </li>
                <li>
                  <h2>{profile.total_leaves}</h2>
                  <h6>Total Leaves</h6>
                </li>
              </ul>

              <ul className="profile-list">
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/applyleave.svg" alt="leave" />
                    </div>
                    <Link to="/nurse/leave" className="profile-box-name">
                      <h5>Apply Leave</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/notification_blue.svg" alt="notification" />
                    </div>
                    <Link to="/nurse/notification" className="profile-box-name">
                      <h5>Notification</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/logout.svg" alt="logout" />
                    </div>
                    <button onClick={handleLogout} className="profile-box-name" style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                      <h5>Log Out</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </>
      )}

      <section className="panel-space"></section>
      <NurseNavbar activeLinks={{ home: false, appointment: false, profile: true }} />
    </div>
  );
};

export default NurseProfile;
