// src/pages/admin/ManagePatient.js
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { getPatientList } from '../../api/admin';
import { formatNRIC, formatDateT } from '../../helpers';

const ManagePatient = () => {
  const tableRef = useRef(null);
  const [patients, setPatients] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const patientData = await getPatientList();
        setPatients(patientData);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching patients:', error);
        setIsLoaded(true);
      }
    };

    fetchPatients();
  }, []);

  useEffect(() => {
    if (tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        stripeClasses: ['odd', 'even'],
      });
    }
  }, [patients]);

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Patient List</h2>
            <Link to="/admin/add-patient" className="add-patient-icon">
              <i className="ri-user-add-line"></i>
            </Link>
          </div>
        </div>
      </header>

      <section className="section-b-space">
        <div className="custom-container">
          {!isLoaded ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
              <h4>Loading...</h4>
            </div>
          ) : (
            <table ref={tableRef} id="example" className="display" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Patient Profile</th>
                  <th>Last Visit</th>
                  <th>Upcoming Visit</th>
                  <th>Total Visit</th>
                </tr>
              </thead>
              <tbody>
                {patients.map((patient) => (
                  <tr key={patient.id}>
                    <td>
                      <Link to={`/admin/edit-patient/${patient.patient_nric}`}>{formatNRIC(patient.patient_nric)}</Link>
                      <br />
                      {patient.patient_name}
                    </td>
                    <td>
                      {patient.last_visit ? (
                        <>
                          <Link to={`/admin/appointment/${patient.last_visit.appt_id}`}>Appt ID: #{patient.last_visit.appt_id}</Link>
                          <br />
                          {formatDateT(patient.last_visit.date).toLocaleString()}
                        </>
                      ) : (
                        'No visits'
                      )}
                    </td>
                    <td>
                      {patient.upcoming_visit ? (
                        <>
                          <Link to={`/admin/appointment/${patient.upcoming_visit.appt_id}`}>Appt ID: #{patient.upcoming_visit.appt_id}</Link>
                          <br />
                          {formatDateT(patient.upcoming_visit.date).toLocaleString()}
                        </>
                      ) : (
                        'No upcoming visits'
                      )}
                    </td>
                    <td>{patient.total_visit || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </section>

      <section className="panel-space"></section>
    </div>
  );
};

export default ManagePatient;
