import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AdminOngoingAppointment from './AdminOngoingAppointment';
import AdminUpcomingAppointment from './AdminUpcomingAppointment';
import AdminCompletedAppointment from './AdminCompletedAppointment';
import AdminSearchAppointment from './AdminSearchAppointment';
import AdminNavbar from '../../components/AdminNavbar';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import Loader from '../../components/Loader';

const AdminAppointment = () => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('ongoing');
  const [appointments, setAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [ongoingAppointments, setOngoingAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);

  useEffect(() => {
    setActiveTab(appointmentId ? 'search' : 'upcoming');
    const q = query(collection(db, 'appointments'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const allAppointments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(allAppointments);
      filterAppointments(allAppointments);
      setIsLoaded(true);
    });

    return () => unsubscribe();
  }, [appointmentId]);

  const filterAppointments = (allAppointments) => {
    setUpcomingAppointments(allAppointments.filter((appointment) => appointment.status === 'Upcoming'));
    setOngoingAppointments(allAppointments.filter((appointment) => appointment.status === 'Ongoing'));
    setCompletedAppointments(allAppointments.filter((appointment) => appointment.status === 'Completed'));
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch(activeTab) {
      case 'upcoming':
        return <AdminUpcomingAppointment appointments={upcomingAppointments} />;
      case 'ongoing':
        return <AdminOngoingAppointment appointments={ongoingAppointments} />;
      case 'completed':
        return <AdminCompletedAppointment appointments={completedAppointments} />;
      case 'search':
        return <AdminSearchAppointment appointments={appointments} searchParam={appointmentId}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <Link to="#" style={{ display: 'none' }}></Link>
            <div className="head-content">
              <div className="text-center">
                <h2>BACKLINERS - DIRECTOR</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="section-b-space">
        <div className="custom-container">
          <ul className="nav nav-pills restaurant-order-tab tab-style2 w-100 border-0 m-0" id="Tab" role="tablist" style={{ justifyContent: 'center' }}>
            <li className="nav-item" role="presentation">
              <button 
                className={`nav-link ${activeTab === 'upcoming' ? 'active' : ''}`}
                onClick={() => handleTabChange('upcoming')}
              >
                Upcoming
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button 
                className={`nav-link ${activeTab === 'ongoing' ? 'active' : ''}`}
                onClick={() => handleTabChange('ongoing')}
              >
                On-going
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button 
                className={`nav-link ${activeTab === 'completed' ? 'active' : ''}`}
                onClick={() => handleTabChange('completed')}
              >
                Completed
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button 
                className={`nav-link ${activeTab === 'search' ? 'active' : ''}`}
                onClick={() => handleTabChange('search')}
              >
                Search
              </button>
            </li>
          </ul>

          {!isLoaded ? (
            <Loader isNav={true} />
          ) : (
            <div className="tab-content restaurant-content" id="TabContent">
              {renderTabContent()}
            </div>
          )}
        </div>
      </section>

      <section className="panel-space"></section>

      <AdminNavbar activeLinks={{ home: false, appointment: true, profile: false }} />
    </div>
  );
};

export default AdminAppointment;