import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { sendNotificationToTopic } from '../../api/notification';
import { getPatientList, getNurseList } from '../../api/admin';

const AdminNotification = () => {
  const [recipient, setRecipient] = useState('0');
  const [message, setMessage] = useState('Hey Everyone! Attention!!!');
  const [title, setTitle] = useState('Notification Title');
  const [patients, setPatients] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [selectedNurse, setSelectedNurse] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');

  useEffect(() => {
    const fetchPatientList = async () => {
      try {
        const patientData = await getPatientList();
        setPatients(patientData);
        if (patientData.length > 0) {
          setSelectedPatient(patientData[0].id);
          //console.log(patientData[0].id);
        }
      } catch (error) {
        console.error('Error fetching patient list:', error);
      }
    };

    const fetchNurseList = async () => {
      try {
        const nurseData = await getNurseList();
        setNurses(nurseData);
        if (nurseData.length > 0) {
          setSelectedNurse(nurseData[0].id);
          //console.log(nurseData[0].id);
        }
      } catch (error) {
        console.error('Error fetching nurse list:', error);
      }
    };

    fetchPatientList();
    fetchNurseList();
  }, []);

  const handleRecipientChange = (event) => {
    setRecipient(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSelectNurse = (event) => {
    //console.log(event.target.value);
    setSelectedNurse(event.target.value);
  };

  const handleSelectPatient = (event) => {
    //console.log(event.target.value);
    setSelectedPatient(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      Swal.fire({
        title: 'Sending...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let topic = '';
      switch (recipient) {
        case '0':
          topic = 'everyone';
          break;
        case '1':
          topic = 'patients';
          break;
        case '2':
          topic = 'nurse';
          break;
        case '3':
          topic = 'patient_' + selectedPatient; // Adjust based on your logic
          break;
        case '4':
          topic = 'nurse_' + selectedNurse;; // Adjust based on your logic
          break;
        default:
          topic = 'everyone';
          break;
      }

      //console.log(topic);
      await sendNotificationToTopic(topic, title, message);

      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Notification Sent',
        text: 'The notification has been sent successfully.',
        confirmButtonColor: '#008893',
      });
    } catch (error) {
      console.error('Error sending notification:', error);
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error sending the notification.',
        confirmButtonColor: '#008893',
      });
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Blast Notification</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" target="_blank" onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">Title</label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={handleTitleChange}
              />
            </div>
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">Recipient</label>
              <select
                name="recipient"
                className="form-control"
                id="recipients"
                value={recipient}
                onChange={handleRecipientChange}
              >
                <option value="0">Everyone</option>
                <option value="1">All Patients</option>
                <option value="2">All Nurses</option>
                <option value="3">Specific Patient</option>
                <option value="4">Specific Nurse</option>
              </select>
            </div>
            {recipient === '3' && (
              <div className="visible" id="patients">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">Recipient</label>
                  <select 
                  name="patientlist" 
                  className="form-control"
                  value={selectedPatient}
                  onChange={handleSelectPatient}
                  >
                    {patients.map(patient => (
                      <option key={patient.patient_nric} value={patient.patient_nric}>
                        {patient.patient_name} ({patient.patient_nric})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {recipient === '4' && (
              <div className="visible" id="nurses">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">Recipient</label>
                  <select 
                    name="nurseslist" 
                    className="form-control"
                    value={selectedNurse}
                    onChange={handleSelectNurse}
                  >
                    {nurses.map(nurse => (
                      <option key={nurse.employee_code} value={nurse.employee_code}>
                        {nurse.name} ({nurse.employee_code})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="form-label fw-semibold">Messages</label>
              <div className="form-input mb-3">
                <textarea
                  rows="5"
                  className="form-control"
                  value={message}
                  onChange={handleMessageChange}
                />
              </div>
            </div>
            <button type="submit" className="btn theme-btn save-address w-100">
              Send Now
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AdminNotification;
