import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateT } from '../../helpers';
import { cancelAppointment } from '../../api/appointment'
import Swal from 'sweetalert2';

const AdminUpcomingAppointment = ({ appointments }) => {

  const handleCancel = async (appointmentId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, cancel it!',
        cancelButtonText: 'No, keep it',
        reverseButtons: true
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: 'Cancelling Appointment...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await cancelAppointment(appointmentId);
        Swal.close();
        Swal.fire({
          icon: 'success',
          title: 'Cancelled!',
          text: 'Appointment has been cancelled.',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Error cancelling appointment: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to cancel the appointment. Please try again.',
        confirmButtonText: 'Close'
      });
    }
  };

  return (
    <section className="section-b-space">
      {!appointments || appointments.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
            <h4>No Data</h4>
          </div>
        ) : (
        <div className="custom-container">
          <ul className="earning-list">
          {appointments.map((appointment, index) => (
            <li key={index}>
              <div className="earning-box">
                <div className="earning-img">
                  <img className="img-fluid earning-icon" src="/assets/images/svg/nurses_blue.svg" alt="receipt" />
                </div>
                <div className="earning-content">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="fw-normal light-text">Nurse In Charge: <b>{appointment.nurse_name}</b></h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h6 className="fw-mediun dark-text">#{appointment.appt_id}</h6>
                  </div>
                </div>
              </div>
              <div className="payment-box order-payment-details">
                <div className="box-content">
                  <h6 className="light-text ps-0 border-0">Patient Name</h6>
                  <h5 className="dark-color mt-1">{appointment.patient_name}</h5>
                </div>
                <div className="box-content border-0 ps-4">
                  <h6 className="light-text ps-0 border-0">Appt. Time</h6>
                  <h5 className="dark-color mt-1">{formatDateT(appointment.appt_date)}</h5>
                </div>
              </div>
              <div className="payment-box order-payment-details">
                <div className="box-content" style={{ flex: 9 }}>
                  <h6 className="light-text ps-0 border-0">Patient Address</h6>
                  <h5 className="dark-text mt-1">{appointment.address}</h5>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3">
                <Link to={`/admin/edit-appointment/${appointment.id}`} className="btn theme-btn w-100 mt-3">Alteration</Link>
                <Link to="#" className="btn theme-btn w-100 mt-3">Call Patient</Link>
              </div>
              <div className="d-flex align-items-center gap-3">
                  <button onClick={() => handleCancel(appointment.id)} className="btn patient-btn w-100 mt-3">Cancel Appointment</button>
                </div>
            </li>
          ))}
          </ul>
        </div>
        )}
    </section>
  );
};

export default AdminUpcomingAppointment;
