import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PatientNavbar from '../../components/PatientNavbar';
import Loader from '../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientProfile } from '../../api/profile';
import { fetchProfile } from '../../redux/profile';
import { unsubscribeFromTopic } from '../../api/notification';
import Swal from 'sweetalert2';

const PatientProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.data);
  const isLoaded = useSelector((state) => state.profile.isLoading);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      // Unsubscribe from the nurse topic
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const patientId = localStorage.getItem('patient_id');
      if (patientId) {
        await unsubscribeFromTopic(`patient_${patientId}`);
        console.log('Unsubscribed from patient topic');
      }
      await unsubscribeFromTopic('patients');
      await unsubscribeFromTopic('everyone');
      // Clear local storage
      localStorage.clear();
      Swal.close();
      console.log('Logged out and cleared local storage.');

      // Navigate to home page
      navigate('/');
    } catch (error) {
      console.error('Error during logout:', error);
      // You might want to show an error message to the user here
      // For now, we'll still clear local storage and navigate away
      localStorage.clear();
      Swal.close();
      navigate('/');
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <Link to="#" style={{ display: 'none' }}>
              {/* Hidden link */}
            </Link>
            <div className="head-content">
              <div className="text-center">
                <h2>BACKLINERS <br />PATIENT DASHBOARD</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isLoaded && <Loader isNav={true} />}

      {!isLoaded && !profile && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
          <h4>No Data</h4>
        </div>
      )}

      {!isLoaded && profile && (
        <>
          <section>
            <div className="custom-container">
              <div className="profile-cover">
                <img className="img-fluid profile-pic" src="/assets/images/svg/user-icons.svg" alt="profile" />
              </div>
              <div className="profile-name">
                <h5>{profile.patient_name}</h5>
              </div>
            </div>
          </section>

          <section>
            <div className="custom-container">
              <ul className="profile-list">
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/assessment2.svg" alt="notification" />
                    </div>
                    <Link to="/patient/healthRecord" className="profile-box-name">
                      <h5>Health Record</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/applyleave.svg" alt="leave" />
                    </div>
                    <Link to={`/patient/requestAppointment/${profile.patient_nric}`} className="profile-box-name">
                      <h5>Request Appointment</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/notification_blue.svg" alt="notification" />
                    </div>
                    <Link to="/patient/notification" className="profile-box-name">
                      <h5>Notification</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="profile-box">
                    <div className="profile-icon">
                      <img className="img-fluid icon" src="/assets/images/svg/logout.svg" alt="logout" />
                    </div>
                    <button onClick={handleLogout} className="profile-box-name" style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                      <h5>Log Out</h5>
                      <i className="ri-arrow-right-s-line arrow"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </>
      )}

      <section className="panel-space"></section>

      <PatientNavbar activeLinks={{ appointment: false, profile: true }} />
    </div>
  );
}

export default PatientProfile;
