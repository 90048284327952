import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthProtectedRoute = ({ children, role }) => {
  const patientId = localStorage.getItem('patient_id');
  const nurseId = localStorage.getItem('nurse_id');
  const adminId = localStorage.getItem('admin_id');

  if (role === 'patient' && !patientId) {
    return <Navigate to="/patient/login" />;
  }

  if (role === 'nurse' && !nurseId) {
    return <Navigate to="/nurse/login" />;
  }

  if (role === 'admin' && !adminId) {
    return <Navigate to="/admin/login" />;
  }

  return children;
};

export default AuthProtectedRoute;
