import React, { useState } from 'react';
import { formatDateT } from '../../helpers'

const AdminOngoingAppointment = ({ appointments }) => {

  return (
    <section className="section-b-space">
      {!appointments || appointments.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
            <h4>No Data</h4>
          </div>
        ) : (
      <div className="custom-container">
        <ul className="earning-list mt-0">
          {appointments.map((appointment, index) => (
            <li key={index}>
              <div className="earning-box">
                <div className="earning-img">
                  <img className="img-fluid earning-icon" src="/assets/images/svg/nurses_blue.svg" alt="receipt" />
                </div>
                <div className="earning-content">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="fw-normal light-text">
                      Nurse In Charge: <b>{appointment.nurse_name}</b>
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h6 className="fw-mediun dark-text">#{appointment.appt_id}</h6>
                  </div>
                </div>
              </div>
              <div className="payment-box order-payment-details">
                <div className="box-content">
                  <h6 className="light-text ps-0 border-0">Patient Name</h6>
                  <h5 className="dark-color mt-1">{appointment.patient_name}</h5>
                </div>
                <div className="box-content border-0 ps-4">
                  <h6 className="light-text ps-0 border-0">Appt. Time</h6>
                  <h5 className="dark-color mt-1">{formatDateT(appointment.appt_date)}</h5>
                </div>
              </div>
              <div className="payment-box order-payment-details">
                <div className="box-content">
                  <h6 className="light-text ps-0 border-0">Check-in Time</h6>
                  <h5 className="success-color mt-1">{formatDateT(appointment.checkin)}</h5>
                </div>
                <div className="box-content border-0 ps-4">
                  <h6 className="light-text ps-0 border-0">Check-out Time</h6>
                  <h5 className="error-color mt-1">{formatDateT(appointment.checkout)}</h5>
                </div>
              </div>
              <div className="payment-box order-payment-details">
                <div className="box-content" style={{ flex: 9 }}>
                  <h6 className="light-text ps-0 border-0">Patient Address</h6>
                  <h5 className="dark-text mt-1">{appointment.address}</h5>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
        )}
    </section>
  );
};

export default AdminOngoingAppointment;
