import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatDateT } from '../../helpers';

const AdminSearchAppointment = ({ appointments, searchParam }) => {
  const [searchQuery, setSearchQuery] = useState(searchParam ? searchParam : "");
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  useEffect(() => {
    filterAppointments();
  }, [searchQuery, appointments]);

  const filterAppointments = () => {
    if (!searchQuery) {
      setFilteredAppointments([]);
      return;
    }

    const filtered = appointments.filter(appointment =>
      appointment.id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredAppointments(filtered[0]);
  };

  return (
    <section className="section-b-space">
      <div className="custom-container">
        <div className="form-group">
          <div className="form-input dark-border-gradient mt-4">
            <label>Appointment ID #</label>
            <input
              type="text"
              className="form-control"
              placeholder="Search by Appointment ID"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        {!filteredAppointments || filteredAppointments.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
            <h4>No Data</h4>
          </div>
        ) : (
          <ul className="earning-list">
            <li>
                <Link to={`/admin/pickup-order-details/${filteredAppointments.id}`}>
                  <div className="earning-box">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/user.svg" alt="receipt" />
                    </div>
                    <div className="earning-content flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="light-text fw-medium">Patient Name</h6>
                        <h6 className="fw-normal light-text">{formatDateT(filteredAppointments.appt_date)}</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-mediun dark-text">{filteredAppointments.patient_name}</h6>
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="earning-box mt-3">
                  <div className="earning-img">
                    <img className="img-fluid earning-icon" src="/assets/images/svg/navigate_now.svg" alt="receipt" />
                  </div>
                  <div className="earning-content flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="light-text fw-medium">Patient Address</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h6 className="fw-mediun dark-text">{filteredAppointments.address}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-item-table">
                  <table className="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th className="text-left">
                          <img className="img-fluid earning-icon" src="/assets/images/svg/assessment.svg" alt="assessment" /> Patient's Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left" colSpan="2">Blood Pressure (BP)</td>
                        <td className="text-end"><b>{filteredAppointments.blood}</b></td>
                      </tr>
                      <tr>
                        <td className="text-left" colSpan="2">Pulse Rate (PR)</td>
                        <td className="text-end"><b>{filteredAppointments.pulse}</b></td>
                      </tr>
                      <tr>
                        <td className="text-left" colSpan="2">Oxygen Saturation (SpO2)</td>
                        <td className="text-end"><b>{filteredAppointments.spo2}</b></td>
                      </tr>
                      <tr>
                        <td className="text-left" colSpan="2">Glucose Level (FBS/PPBS)</td>
                        <td className="text-end"><b>{filteredAppointments.glucose}</b></td>
                      </tr>
                      <tr>
                        <td colSpan="2">Service of the day: <b>{filteredAppointments.services}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
          </ul>
        )}
      </div>
    </section>
  );
};

export default AdminSearchAppointment;
