import { nurse, patients } from "../firebaseCollection";
import { db } from '../../firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

export const getPatientProfile = async () => {
  const patient_nric = localStorage.getItem('patient_nric');
  try {
    const docRef = doc(db, patients, patient_nric);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('Document does not exist');
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
  }
};

export const getNurseProfile = async () => {
  const nurse_id = localStorage.getItem('nurse_id');
  try {
    const docRef = doc(db, nurse, nurse_id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('Document does not exist');
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
  }
};