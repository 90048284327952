import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getNurseAppointments, nurseCheckout, updatePatientAssessment } from '../../api/appointment';
import Loader from '../../components/Loader';
import { formatDateT } from '../../helpers';

const OngoingAppointment = ({ appointments }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [assessments, setAssessments] = useState({});

  const handleCheckout = async (appointment) => {
    try {
      setIsLoaded(false);
      Swal.fire({
        title: 'Checking Out...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const assessment = assessments[appointment.id];
      const assessmentData = {
        blood: assessment.bp,
        pulse: assessment.pr,
        glucose: assessment.glucose,
        spo2: assessment.spo2,
        services: assessment.service ?? "N/A",
      };

      await nurseCheckout(appointment.id, assessmentData, appointment);
      await updatePatientAssessment(appointment.id, appointment.patient_nric, assessmentData);

      Swal.close();
      setIsLoaded(true);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Appointment status updated to Completed.',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      Swal.close();
      setIsLoaded(true);
      console.error('Error during checkout: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to complete the appointment. Please try again.',
        confirmButtonText: 'Close'
      });
    }
  };

  const updateAssessment = (appointmentId, field, value) => {
    setAssessments(prevState => ({
      ...prevState,
      [appointmentId]: {
        ...prevState[appointmentId],
        [field]: value,
      }
    }));
  };

  return (
    <div className="tab-pane fade show active" id="ongoing-tab" role="tabpanel" aria-labelledby="pill-ongoing-tab">
      <section className="section-b-space">
        {!appointments || appointments.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
            <h4>No Data Ongoing</h4>
          </div>
        ) : (
          <div className="">
            <ul className="earning-list mt-0">
              {appointments.map((appointment, index) => (
                <li key={index}>
                  <div className="earning-box">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/nurses_blue.svg" alt="receipt" />
                    </div>
                    <div className="earning-content">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="fw-normal light-text">Nurse In Charge: <b>{appointment.nurse_name}</b></h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-mediun dark-text">#{appointment.id}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="payment-box order-payment-details">
                    <div className="box-content">
                      <h6 className="light-text ps-0 border-0">Patient Name</h6>
                      <h5 className="success-color mt-1">{appointment.patient_name}</h5>
                    </div>
                    <div className="box-content border-0 ps-4">
                      <h6 className="light-text ps-0 border-0">Appt. Time</h6>
                      <h5 className="success-color mt-1">{formatDateT(appointment.appt_date)}</h5>
                    </div>
                  </div>
                  <div className="payment-box order-payment-details">
                    <div className="box-content" style={{ flex: 9 }}>
                      <h6 className="light-text ps-0 border-0">Patient Address</h6>
                      <h5 className="dark-text mt-1">{appointment.address}</h5>
                    </div>
                  </div>
                  <PatientAssessment
                    appointment={appointment}
                    assessment={assessments[appointment.id] || {}}
                    updateAssessment={updateAssessment}
                  />
                  <button
                    onClick={() => handleCheckout(appointment)}
                    className="btn theme-btn w-100 mt-3"
                  >
                    Confirm
                  </button>
                  <p>Note: By confirming, the system will clock you out from this visit.</p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
    </div>
  );
};

const PatientAssessment = ({ appointment, assessment, updateAssessment }) => {
  useEffect(() => {
    if (appointment.assessment) {
      updateAssessment(appointment.id, 'bp', appointment.assessment.bp || '120');
      updateAssessment(appointment.id, 'pr', appointment.assessment.pr || '90');
      updateAssessment(appointment.id, 'spo2', appointment.assessment.spo2 || '95');
      updateAssessment(appointment.id, 'glucose', appointment.assessment.glucose || '100');
      updateAssessment(appointment.id, 'service', appointment.assessment.service || '');
    }
  }, [appointment, updateAssessment]);

  return (
    <div className="order-item-table">
      <table className="table table-borderless mb-0">
        <thead>
          <tr>
            <th className="text-left"><img className="img-fluid earning-icon" src="/assets/images/svg/assessment.svg" alt="assessment" /> Patient's Assessment</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left" colSpan="2">Blood Pressure (BP)</td>
            <td className="text-end">
              <input
                type="text"
                className="form-control assesssmentinput"
                maxLength="7"
                value={assessment.bp || ''}
                onChange={(e) => updateAssessment(appointment.id, 'bp', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="text-left" colSpan="2">Pulse Rate (PR)</td>
            <td className="text-end">
              <input
                type="number"
                className="form-control assesssmentinput"
                maxLength="3"
                value={assessment.pr || ''}
                onChange={(e) => updateAssessment(appointment.id, 'pr', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="text-left" colSpan="2">Oxygen Saturation (SpO2)</td>
            <td className="text-end">
              <input
                type="number"
                className="form-control assesssmentinput"
                maxLength="3"
                value={assessment.spo2 || ''}
                onChange={(e) => updateAssessment(appointment.id, 'spo2', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="text-left" colSpan="2">Glucose Level (FBS/PPBS)</td>
            <td className="text-end">
              <input
                type="number"
                className="form-control assesssmentinput"
                maxLength="3"
                value={assessment.glucose || ''}
                onChange={(e) => updateAssessment(appointment.id, 'glucose', e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="3">Service of the day</td>
          </tr>
          <tr>
            <td colSpan="3">
              <textarea
                className="form-control"
                rows="5"
                value={assessment.service || ''}
                onChange={(e) => updateAssessment(appointment.id, 'service', e.target.value)}
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OngoingAppointment;
