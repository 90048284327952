const nurse = "nurse";
const patients = "patients";
const appointments = "appointments";
const assessments = "assessments";
const leaves = "leaves";

export { 
    nurse, 
    patients,
    appointments,
    assessments,
    leaves
};