import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Chart, LineElement, LineController, CategoryScale, LinearScale, PointElement } from 'chart.js';
import { getPatientHealthRecords } from '../../api/healthRecord';
import Loader from '../../components/Loader';
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';

// Register Chart.js components
Chart.register(LineElement, LineController, CategoryScale, LinearScale, PointElement);

const HealthRecord = () => {
  const glucoseChartRef = useRef(null);
  const pulseChartRef = useRef(null);
  const bloodChartRef = useRef(null);
  const spoChartRef = useRef(null);
  const profile = useSelector((state) => state.profile.data);

  const [isLoaded, setIsLoaded] = useState(false);
  const [healthRecord, setHealthRecord] = useState(null);

  useEffect(() => {
    fetchHealthRecord();
  }, []);

  const fetchHealthRecord = async () => {
    try {
      const data = await getPatientHealthRecords(profile.patient_nric);
      const formattedData = formatHealthRecord(data);
      console.log(formattedData);
      setHealthRecord(formattedData);
      setIsLoaded(true);
    } catch (error) {
      console.error(error);
      setIsLoaded(true);
      Swal.fire({
        icon: "error",
        title: "Health Record",
        text: error.message,
        confirmButtonColor: "#008893"
      });
    }
  };

  const formatHealthRecord = (data) => {
    const measurements = {
      glucose: [],
      pulse: [],
      bp: [],
      spo2: []
    };

    data.forEach(record => {
      const date = new Date(record.assessment_date.seconds * 1000).toLocaleDateString();
      console.log(date);
      measurements.glucose.push({ date, value: record.glucose });
      measurements.pulse.push({ date, value: record.pulse });
      measurements.bp.push({ date, value: record.blood });
      measurements.spo2.push({ date, value: record.spo2 });
    });

    return {
      patient_name: data.length > 0 ? data[0].patient_name : '',
      last_assessment: data.length > 0 ? data[0].assessment_date.toDate() : '',
      measurements
    };
  };

  useEffect(() => {
    if (!healthRecord) return;

    const createChart = (ctx, label, data, borderColor, backgroundColor) => {
      if (ctx.chartRef) ctx.chartRef.destroy();
      ctx.chartRef = new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map(d => d.date),
          datasets: [{
            label,
            data: data.map(d => d.value),
            borderColor,
            backgroundColor,
            fill: false,
          }]
        },
        options: {
          scales: {
            x: { title: { display: true, text: 'Date' } },
            y: { title: { display: true, text: 'Values' } }
          },
          responsive: true,
          maintainAspectRatio: false,
          tension: 0.4
        }
      });
    };

    createChart(glucoseChartRef.current.getContext('2d'), 'Glucose Level (mg/dL)', healthRecord.measurements.glucose, 'rgba(75, 192, 192, 1)', 'rgba(75, 192, 192, 0.2)');
    createChart(pulseChartRef.current.getContext('2d'), 'Pulse Rate (bpm)', healthRecord.measurements.pulse, 'rgba(214, 51, 132, 1)', 'rgba(214, 51, 132, 0.2)');
    createChart(bloodChartRef.current.getContext('2d'), 'Blood Pressure (mm Hg)', healthRecord.measurements.bp, 'rgba(192, 75, 75, 1)', 'rgba(192, 75, 75, 0.2)');
    createChart(spoChartRef.current.getContext('2d'), 'SpO2 (%)', healthRecord.measurements.spo2, 'rgba(13, 110, 253, 1)', 'rgba(13, 110, 253, 0.2)');

  }, [healthRecord]);

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/patient/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Health Record</h2>
          </div>
        </div>
      </header>

      {!isLoaded && <Loader isNav={true} />}

      {isLoaded && !healthRecord && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
          <h4>No Data</h4>
        </div>
      )}

      {isLoaded && healthRecord && (
        <>
          <section className="section-b-space">
            <div className="custom-container">
              <ul className="earning-list">
                <li>
                  <div className="earning-box">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/user.svg" alt="receipt" />
                    </div>
                    <div className="earning-content flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="light-text fw-medium">Patient Name</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-medium dark-text">{profile.patient_name}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="earning-box mt-3">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/applyleave.svg" alt="receipt" />
                    </div>
                    <div className="earning-content flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="light-text fw-medium">Last Assessment Date</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-medium dark-text">{new Date(healthRecord.last_assessment).toLocaleDateString()}</h6>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <section className="section-b-space">
            <div className="custom-container">
              <div className="chart-container">
                <canvas ref={glucoseChartRef} id="glucoseChart" style={{ width: '100%' }}></canvas>
              </div>
              <div className="table-container" style={{ width: "100%", margin: "20px 0" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Date</th>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Glucose Level (mg/dL)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {healthRecord.measurements.glucose.map((record, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{new Date(record.date).toLocaleDateString()}</td>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{record.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <section className="panel-space">
              <hr />
            </section>
            <div className="custom-container">
              <div className="chart-container">
                <canvas ref={pulseChartRef} id="pulseChart" style={{ width: '100%' }}></canvas>
              </div>
              <div className="table-container" style={{ width: "100%", margin: "20px 0" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Date</th>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Pulse Rate (bpm)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {healthRecord.measurements.pulse.map((record, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{new Date(record.date).toLocaleDateString()}</td>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{record.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <section className="panel-space">
              <hr />
            </section>
            <div className="custom-container">
              <div className="chart-container">
                <canvas ref={bloodChartRef} id="bloodChart" style={{ width: '100%' }}></canvas>
              </div>
              <div className="table-container" style={{ width: "100%", margin: "20px 0" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Date</th>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Blood Pressure (mm Hg)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {healthRecord.measurements.bp.map((record, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{new Date(record.date).toLocaleDateString()}</td>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{record.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <section className="panel-space">
              <hr />
            </section>
            <div className="custom-container">
              <div className="chart-container">
                <canvas ref={spoChartRef} id="spoChart" style={{ width: '100%' }}></canvas>
              </div>
              <div className="table-container" style={{ width: "100%", margin: "20px 0" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>Date</th>
                      <th style={{ border: "1px solid #ddd", padding: "10px" }}>SpO2 (%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {healthRecord.measurements.spo2.map((record, index) => (
                      <tr key={index}>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{new Date(record.date).toLocaleDateString()}</td>
                        <td style={{ border: "1px solid #ddd", padding: "10px" }}>{record.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default HealthRecord;
