import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createAppointment } from '../../api/appointment';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Timestamp, GeoPoint } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';

const RequestAppointment = () => {
  const [date, setDate] = useState(new Date());
  const [remark, setRemark] = useState('');
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile.data);

  const handleSubmit = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: 'Requesting Appointment...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (!date) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select a date for the appointment application.',
        confirmButtonColor: '#008893',
      });
      return;
    }

    try {
      const appointmentData = {
        address: profile.address,
        appt_date: Timestamp.fromDate(date),
        services: remark,
        blood: "",
        date_added: Timestamp.now(),
        //geopoint: new GeoPoint(profile.geopoint.latitude, profile.geopoint.longitude),
        nurse_id: "",
        nurse_name: "",
        patient_name: profile.patient_name,
        patient_nric: profile.patient_nric,
        pulse:"",
        spo2:"",
        status:"Pending"
      };

      await createAppointment(appointmentData);
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Appointment Requested',
        text: 'Your appointment request has been submitted successfully.',
        confirmButtonColor: '#008893'
      }).then(() => {
        navigate('/patient/profile');
      });
    } catch (error) {
      console.error('Error requesting appointment:', error);
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Request Failed',
        text: 'There was an error requesting the appointment. Please try again later.',
        confirmButtonColor: '#008893'
      });
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/patient/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Request Appointment</h2>
          </div>
        </div>
      </header>
      
      <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-group mb-3">
              <label className="form-label fw-semibold">Date of application</label>
                <div className="form-input mb-3">
                  <DatePicker
                    selected={date}
                    onChange={(date) => setDate(date)}
                    showTimeSelect
                    dateFormat="Pp"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Service required (Optional)</label>
              <div className="form-input mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Wound dressing" 
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </div>
            </div>
            <button type="submit" className="btn theme-btn save-address w-100">Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default RequestAppointment;
