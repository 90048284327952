import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { getPatientAppointments } from '../../api/appointment';
import PatientNavbar from '../../components/PatientNavbar';
import { formatDateT } from '../../helpers';

const PatientAppointment = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    fetchAppointmentList();
  }, []);

  const fetchAppointmentList = async () => {
    try {
      const data = await getPatientAppointments();
      setAppointments(data);
      setIsLoaded(true);
    } catch (error) {
      console.error(error);
      setIsLoaded(true); // Stop loading even if there's an error
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <Link to="#" style={{ display: 'none' }}>
              {/* Hidden link */}
            </Link>
            <div className="head-content">
              <div className="text-center">
                <h2>BACKLINERS <br />MY APPOINTMENT</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      {!isLoaded ? (
        <Loader isNav={true} />
      ) : !appointments || appointments.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
          <h4>No Data</h4>
        </div>
      ) : (
        <section className="section-b-space">
          <div className="custom-container">
            <ul className="earning-list">
              {appointments.map((appointment) => (
                <li key={appointment.appt_id}>
                  <div className="earning-box">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/user.svg" alt="receipt" />
                    </div>

                    <div className="earning-content flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="light-text fw-medium">Nurse In Charge</h6>
                        <span className={appointment.status === 'Completed' ? 'success-color' : 'theme-color'}>
                          {appointment.status}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-medium dark-text">{appointment.nurse_name}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="earning-box mt-3">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/applyleave.svg" alt="receipt" />
                    </div>

                    <div className="earning-content flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="light-text fw-medium">Appointment Date</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-medium dark-text">{formatDateT(appointment.appt_date)}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="earning-box mt-3">
                    <div className="earning-img">
                      <img className="img-fluid earning-icon" src="/assets/images/svg/navigate_now.svg" alt="receipt" />
                    </div>

                    <div className="earning-content flex-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="light-text fw-medium">Service Address</h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2">
                        <h6 className="fw-medium dark-text">{appointment.address}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="order-item-table">
                    <table className="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th className="text-left">
                            <img className="img-fluid earning-icon" src="/assets/images/svg/assessment.svg" alt="Patient's Assessment" />
                            Patient's Assessment
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left" colSpan="2">Blood Pressure (BP)</td>
                          <td className="text-end"><b>{appointment.blood}</b></td>
                        </tr>
                        <tr>
                          <td className="text-left" colSpan="2">Pulse Rate (PR)</td>
                          <td className="text-end"><b>{appointment.pulse}</b></td>
                        </tr>
                        <tr>
                          <td className="text-left" colSpan="2">Oxygen Saturation (SpO2)</td>
                          <td className="text-end"><b>{appointment.spo2}</b></td>
                        </tr>
                        <tr>
                          <td className="text-left" colSpan="2">Glucose Level (FBS/PPBS)</td>
                          <td className="text-end"><b>{appointment.glucose}</b></td>
                        </tr>
                        <tr>
                          <td colSpan="2">Service of the day: <b>{appointment.services}</b></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}

      <section className="panel-space"></section>

      <PatientNavbar activeLinks={{ appointment: true, profile: false }} />
    </div>
  );
}

export default PatientAppointment;
