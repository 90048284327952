import { format } from 'date-fns';

export const removeDashes = (str) => {
    return str.replace(/-/g, '');
};

export const formatNRIC = (nric) => {
    if (nric && nric.length === 12) {
      return `${nric.slice(0, 6)}-${nric.slice(6, 8)}-${nric.slice(8)}`;
    }
    return nric;
};

export const formatDateD = (timestamp) => {
    if (timestamp) {
        const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        const options = {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        };
        return date.toLocaleString('en-GB', options).replace(',', '');
    }
    else {
        return '';
    }
}

export const formatDateT = (timestamp) => {
    if (timestamp) {
        const date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        const options = {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        };
        return date.toLocaleString('en-GB', options).replace(',', '');
    }
    else {
        return '';
    }
};

export const formatDateMY = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
    return format(date, 'MMMM yyyy'); // Format the date to "June 2024"
};
