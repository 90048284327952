import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

const Loader = (isNav) => {
    const containerClass = isNav ? 'loader-container-nav' : 'loader-container';
    return (
      <div className={`d-flex justify-content-center align-items-center ${containerClass}`}>
        <div className="spinner-border" role="status">
          {/* Remove loading text */}
        </div>
      </div>
    );
};

export default Loader;