import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { getNurseNotification } from '../../api/notification';
import { formatDateT } from '../../helpers';

const NurseNotification = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotificationList();
  }, []);

  const fetchNotificationList = async () => {
    try {
      const data = await getNurseNotification();
      console.log(data);

      // Sort notifications by timestamp (latest to oldest)
      const sortedData = data.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

      setNotifications(sortedData);
      setIsLoaded(true);
    } catch (error) {
      console.error(error);
      setIsLoaded(true); // Stop loading even if there's an error
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/nurse/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Notification</h2>
          </div>
        </div>
      </header>

      {!isLoaded && <Loader isNav={true} />}

      {isLoaded && notifications.length === 0 && (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
          <h4>No Data</h4>
        </div>
      )}

      {isLoaded && notifications.length > 0 && (
        <section className="section-b-space pt-0">
          <div className="custom-container">
            <ul className="notification">
              {notifications.map((notification, index) => (
                <li key={index} className="notification-box unread">
                  <div className="notification-icon">
                    <span>
                      <img
                        className="notification-img img1"
                        src="/assets/images/svg/add.svg"
                        alt="notification"
                      />
                    </span>
                  </div>
                  <div className="notification-content">
                    <h5>{notification.title}</h5>
                    <h6>{formatDateT(notification.timestamp)}</h6>
                    <p>{notification.body}</p>
                  </div>
                </li>
              ))}
              {/* <li className="notification-box">
                <div className="notification-icon">
                  <span>
                    <img className="notification-img img1" src="../assets/images/svg/close.svg" alt="add" />
                  </span>
                </div>
                <div className="notification-content">
                  <h5>Appointment Rejected</h5>
                  <h6>30/5/2024 15:30pm</h6>
                  <p>Your appointment request for 30/5/2024 has been rejected. Please try another date.</p>
                </div>
              </li> */}
            </ul>
          </div>
        </section>
      )}
    </div>
  );
};

export default NurseNotification;
