import { appointments, patients, nurse } from "../firebaseCollection";
import { db } from '../../firebase/firebase';
import { collection, setDoc, getDocs, query, where, doc, Timestamp, updateDoc, getDoc } from 'firebase/firestore';
import bcrypt from 'bcryptjs';


export const createPatient = async (patientData) => {
    try {
      const patientRef = doc(db, patients, patientData.patient_nric);
      const docSnap = await getDoc(patientRef);
  
      if (docSnap.exists()) {
        throw new Error('Patient ID already exists');
      }
  
      await setDoc(patientRef, patientData);
      console.log('Patient added successfully');
    } catch (error) {
      console.error('Error adding patient:', error);
      throw error;
    }
};


export const createNurse = async (nurseData) => {
    const { username, password, ...otherData } = nurseData;
    const hashedPassword = await bcrypt.hash(password, 10);
  
    await setDoc(doc(db, nurse, username), {
      employee_code: username,
      password: hashedPassword,
      ...otherData,
      date_joined: Timestamp.fromDate(new Date(otherData.date_joined))
    });
  
    console.log('Nurse registered successfully');
};

export const checkUsernameExists = async (username) => {
    const docRef = doc(db, nurse, username);
    const docSnap = await getDoc(docRef);
  
    return docSnap.exists();
};

export const getPatientList = async () => {
    try {
      const patientsCollection = collection(db, patients);
      const querySnapshot = await getDocs(patientsCollection);
      const patientList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return patientList;
    } catch (error) {
      console.error('Error fetching patients: ', error);
      throw error;
    }
};

export const getPatientProfile = async (nric) => {
    try {
      const docRef = doc(db, patients, nric);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log('Document does not exist');
      }
    } catch (error) {
      console.error('Error fetching document: ', error);
    }
};

export const updatePatient = async (patientId, patientData) => {
    const patientRef = doc(db, patients, patientId);
    try {
      await updateDoc(patientRef, patientData);
      console.log('Patient updated successfully');
    } catch (error) {
      console.error('Error updating patient: ', error);
      throw error;
    }
};

export const getNurseProfile = async (nurse_id) => {
    try {
      const docRef = doc(db, nurse, nurse_id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.log('Document does not exist');
      }
    } catch (error) {
      console.error('Error fetching document: ', error);
    }
};

export const getNurseList = async () => {
    try {
      const nursesCollection = collection(db, nurse);
      const querySnapshot = await getDocs(nursesCollection);
      const nurseList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return nurseList;
    } catch (error) {
      console.error('Error fetching nurses: ', error);
      throw error;
    }
};

export const updateNurse = async (nurseId, nurseData) => {
    try {
      const nurseRef = doc(db, nurse, nurseId);
      await updateDoc(nurseRef, nurseData);
      console.log('Nurse updated successfully');
    } catch (error) {
      console.error('Error updating nurse:', error);
      throw error;
    }
};