const api_config = {
  headers: {},
};

const api_server = "https://bkl.hawkydocky.com/api/";

//auth
const uLogin = api_server + "ulogin";
const nLogin = api_server + "nlogin";

//profile
const uProfile = api_server + "uprofile";
const nProfile = api_server + "nprofile";

//Notification
const uNotification = api_server + "uprofile/notifications";
const nNotification = api_server + "nprofile/notifications";

//Appointment
const uAppointment = api_server + "uprofile/appointments";
const nAppointment = api_server + "nprofile/appointments";
const uMakeAppointment = api_server + "uprofile/makeappointments";

///Health Record
const uHealthRecord = api_server + "uprofile/healthrecords";

///Leave
const nApplyLeave = api_server + "nprofile/applyleave";

///Dashboard
const nDashboard = api_server + "nprofile/dashboard";

///Check-in & Check-out
const nCheckin = api_server + "nprofile/checkin";
const nCheckout = api_server + "nprofile/checkout";


module.exports = {
  api_config,
  uLogin,
  nLogin,
  uProfile,
  nProfile,
  uNotification,
  nNotification,
  uAppointment,
  nAppointment,
  uMakeAppointment,
  uHealthRecord,
  nApplyLeave,
  nDashboard,
  nCheckin,
  nCheckout
};
