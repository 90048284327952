import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { collection, query, onSnapshot, updateDoc, doc, increment} from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import Swal from 'sweetalert2';
import { sendNotificationToTopic } from '../../api/notification';
import { formatDateD } from '../../helpers';

const ManageLeave = () => {
  const tableRef = useRef(null);
  const [leaves, setLeaves] = useState([]);
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    const fetchLeaves = () => {
      const q = query(collection(db, 'leaves'));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const leaveData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setLeaves(leaveData);
      });

      return () => unsubscribe();
    };

    fetchLeaves();

    // Initialize DataTable once when component mounts
    if (tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
      const dataTableInstance = $(tableRef.current).DataTable({
        stripeClasses: ['odd', 'even'],
        data: [], // Initial empty data
        columns: [
          { title: 'Nurse Profile', data: 'nurse_profile' },
          { title: 'Date', data: 'date' },
          { title: 'Reason', data: 'reason' },
          { title: 'Status', data: 'status' },
          { 
            title: 'Action', 
            data: null,
            render: function(data, type, row) {
              if (row.status === 'Pending') {
                return `
                  <small>
                    <a href="#" class="approve-link" data-id="${row.id}">Approve</a>
                    <br />
                    <a href="#" class="reject-link" data-id="${row.id}">Reject</a>
                  </small>
                `;
              }
              return '';
            }
          }
        ]
      });
      setDataTable(dataTableInstance);
    }
  }, []);

  useEffect(() => {
    if (dataTable && leaves.length) {
      // Clear existing data
      dataTable.clear();

      // Format the leaves data for DataTable
      const formattedData = leaves.map(leave => ({
        id: leave.id,
        nurse_profile: `${leave.nurse_profile.nurse_name}<br/>(${leave.nurse_profile.nurse_id})`,
        date: new Date(leave.date.seconds * 1000).toLocaleDateString(),
        reason: leave.reason,
        status: leave.status
      }));

      // Add new data and redraw the table
      dataTable.rows.add(formattedData).draw();

      // Attach event listeners after data is loaded
      $(tableRef.current).off('click', '.approve-link').on('click', '.approve-link', function(e) {
        e.preventDefault();
        console.log('Approve link clicked');
        const leaveId = $(this).data('id');
        console.log('Leave ID:', leaveId);
        const leave = leaves.find(l => l.id === leaveId);
        console.log('Leave object:', leave);
        if (leave) {
          handleApprove(leave);
        } else {
          console.error('Leave not found for ID:', leaveId);
        }
      });

      $(tableRef.current).off('click', '.reject-link').on('click', '.reject-link', function(e) {
        e.preventDefault();
        console.log('Reject link clicked');
        const leaveId = $(this).data('id');
        console.log('Leave ID:', leaveId);
        const leave = leaves.find(l => l.id === leaveId);
        console.log('Leave object:', leave);
        if (leave) {
          handleReject(leave);
        } else {
          console.error('Leave not found for ID:', leaveId);
        }
      });
    }
  }, [leaves, dataTable]);

  const handleApprove = async (leave) => {
    console.log('handleApprove called with:', leave);
    Swal.fire({
      title: 'Approving Leave',
      text: 'Please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const leaveRef = doc(db, 'leaves', leave.id);
      await updateDoc(leaveRef, {
        status: 'Approved'
      });
      const nurseRef = doc(db, 'nurse', leave.nurse_profile.nurse_id);
      await updateDoc(nurseRef, {
        total_leaves: increment(-1)
      });
      const notificationTitle = 'Leave Approved';
      const notificationBody = `Your leave application for ${formatDateD(leave.date)} has been approved. Enjoy your time off!`;

      await sendNotificationToTopic(
        'nurse_' + leave.nurse_profile.nurse_id, 
        notificationTitle, 
        notificationBody
      );
      Swal.fire({
        icon: 'success',
        title: 'Leave Approved',
        text: 'Leave approved successfully',
        confirmButtonColor: '#008893'
      });
    } catch (error) {
      console.error('Error approving leave:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error approving the leave.',
        confirmButtonColor: '#008893'
      });
    }
  };

  const handleReject = async (leave) => {
    console.log('handleReject called with ID:', leave);
    Swal.fire({
      title: 'Rejecting Leave',
      text: 'Please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      const leaveRef = doc(db, 'leaves', leave.id);
      await updateDoc(leaveRef, {
        status: 'Rejected'
      });
      const notificationTitle = 'Leave Approved';
      const notificationBody = `Your leave application for ${formatDateD(leave.date)} has been rejected.`;

      await sendNotificationToTopic(
        'nurse_' + leave.nurse_profile.nurse_id, 
        notificationTitle, 
        notificationBody
      );
      Swal.fire({
        icon: 'success',
        title: 'Leave Rejected',
        text: 'Leave rejected successfully',
        confirmButtonColor: '#008893'
      });
    } catch (error) {
      console.error('Error rejecting leave:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error rejecting the leave.',
        confirmButtonColor: '#008893'
      });
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Leave Approval</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space">
        <div className="custom-container">
          <table ref={tableRef} id="example" className="display" style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Nurse Profile</th>
                <th>Date</th>
                <th>Reason</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>
        </div>
      </section>

      <section className="panel-space"></section>
    </div>
  );
};

export default ManageLeave;