import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { adminChangePassword } from '../../api/auth'; // Adjust this import path as needed

const AdminEditProfile = () => {
  const [displayName, setDisplayName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [encryption] = useState('SHA256');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the current display name from localStorage or your state management solution
    const currentDisplayName = localStorage.getItem('admin_display_name') || '';
    setDisplayName(currentDisplayName);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'New password and confirm password do not match',
        confirmButtonColor: '#008893',
      });
      return;
    }

    if (newPassword && !oldPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please provide your old password to change the password',
        confirmButtonColor: '#008893',
      });
      return;
    }

    Swal.fire({
      title: 'Updating...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      // Only call the change password function if a new password is provided
      if (newPassword) {
        const username = localStorage.getItem('admin_username');
        const response = await adminChangePassword(username, oldPassword, newPassword, encryption);
        
        if (response.status === 200) {
          // Clear password fields
          setOldPassword('');
          setNewPassword('');
          setConfirmPassword('');
          Swal.close();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password changed successfully',
            confirmButtonColor: '#008893',
          });
        } else {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.message || 'Failed to change password',
            confirmButtonColor: '#008893',
          });
          return;
        }
      }

      // Update display name in localStorage (or your state management solution)
      localStorage.setItem('admin_display_name', displayName);
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Profile updated successfully',
        confirmButtonColor: '#008893',
      });

      // Optionally, redirect after a short delay
      setTimeout(() => navigate('/admin/profile'), 2000);
    } catch (error) {
      console.error('Error updating profile:', error);
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data,
        confirmButtonColor: '#008893',
      });
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Manage Profile</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">Display Name</label>
              <input
                type="text"
                className="form-control"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
            
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">Encryption</label>
              <input
                type="text"
                className="form-control"
                value={encryption}
                readOnly
              />
            </div>
            
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">Old Password</label>
              <input
                type="password"
                className="form-control"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Enter old password to change"
              />
            </div>
            
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">New Password</label>
              <input
                type="password"
                className="form-control"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </div>
            
            <div className="form-group mb-3">
              <label className="form-label fw-semibold">Confirm New Password</label>
              <input
                type="password"
                className="form-control"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
              />
            </div>
            
            <button type="submit" className="btn theme-btn save-address w-100">Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AdminEditProfile;
