import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createNurse, checkUsernameExists } from '../../api/admin';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddNurse = () => {
  const navigate = useNavigate();
  const [nurseName, setNurseName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState('Active');
  const [totalLeave, setTotalLeave] = useState('');
  const [dateJoined, setDateJoined] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleNurseNameChange = (event) => {
    setNurseName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTotalLeaveChange = (event) => {
    setTotalLeave(event.target.value.replace(/\D/g, '')); // Only allow numbers
  };

  const handleDateJoinedChange = (date) => {
    setDateJoined(date);
  };

  const handleAddNurse = async (e) => {
    e.preventDefault();

    if (!nurseName || !username || !password || !status || !totalLeave || !dateJoined) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'All fields are required.',
        confirmButtonColor: '#008893',
      });
      return;
    }

    if (password.length < 6) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Password must be at least 6 characters long.',
        confirmButtonColor: '#008893',
      });
      return;
    }

    try {
      const usernameExists = await checkUsernameExists(username);

      if (usernameExists) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Username already exists. Please choose a different username.',
          confirmButtonColor: '#008893',
        });
        return;
      }

      setIsSubmitting(true);
      Swal.fire({
        title: 'Adding...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await createNurse({
        name: nurseName,
        username: username,
        password: password,
        status: status,
        total_leaves: parseInt(totalLeave, 10),
        date_joined: dateJoined,
      });

      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Nurse added successfully.',
        confirmButtonColor: '#008893',
      }).then(() => {
        navigate('/admin/nurse-list');
      });
    } catch (error) {
      console.error('Error adding nurse:', error);
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error adding the nurse. Please try again later.',
        confirmButtonColor: '#008893',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/nurse-list">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Add Nurse</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          {isSubmitting ? (
            <Loader isNav={true} />
          ) : (
            <form className="auth-form profile-form" onSubmit={handleAddNurse}>
              <div className="form-group">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">Nurse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nurseName}
                    onChange={handleNurseNameChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Username</label>
                <div className="form-input mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Password</label>
                <div className="form-input mb-3">
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Total Leave</label>
                <div className="form-input mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={totalLeave}
                    onChange={handleTotalLeaveChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Date Joined</label>
                <div className="form-input mb-3">
                  <DatePicker
                    selected={dateJoined}
                    onChange={handleDateJoinedChange}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Status</label>
                <div className="form-input mb-3">
                  <select name="status" className="form-control" value={status} onChange={handleStatusChange}>
                    <option value="Active">Active</option>
                    <option value="Suspend">Suspend</option>
                  </select>
                </div>
              </div>
              <button type="submit" className="btn theme-btn save-address w-100">
                Save
              </button>
            </form>
          )}
        </div>
      </section>
    </div>
  );
};

export default AddNurse;
