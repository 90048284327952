import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getAppointmentById, updateAppointment } from '../../api/appointment';
import { getNurseList } from '../../api/admin';
import Loader from '../../components/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';

const AdminEditAppointment = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const [formData, setFormData] = useState(null);
  const [nurseList, setNurseList] = useState([]);
  const [serviceDate, setServiceDate] = useState(new Date());
  const [nurse, setNurse] = useState('');

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const appointment = await getAppointmentById(appointmentId);
        setFormData({
          patientName: appointment.patient_name,
          address: appointment.address,
          nurseInCharge: appointment.nurse_name,
          remark: appointment.services,
        });
        setServiceDate(new Date(appointment.appt_date.seconds * 1000));
      } catch (error) {
        console.error('Error fetching appointment:', error);
      } finally {
        setIsLoaded(true);
      }
    };

    const fetchNurseList = async () => {
      try {
        const nurses = await getNurseList();
        setNurseList(nurses);
      } catch (error) {
        console.error('Error fetching nurse list:', error);
      }
    };

    if (appointmentId) {
      fetchAppointment();
      fetchNurseList();
    }
  }, [appointmentId]);

  useEffect(() => {
    if (formData && nurseList.length > 0) {
      const selectedNurse = nurseList.find(n => n.name === formData.nurseInCharge);
      setNurse(selectedNurse);
    }
  }, [formData, nurseList]);

  const handleChange = (e) => {
    const nurseId = e.target.value;
    const selectedNurse = nurseList.find(n => n.id === nurseId);
    setNurse(selectedNurse);
  };

  const handleDateChange = (date) => {
    setServiceDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Updating...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await updateAppointment(appointmentId, {
        nurse_name: nurse.name,
        nurse_id: nurse.id,
        appt_date: serviceDate,
        status: "Upcoming"
      });
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Appointment updated successfully.',
        confirmButtonText: 'OK'
      }).then(() => {
        navigate('/admin/appointment');
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to update appointment. Please try again.',
        confirmButtonText: 'Close'
      });
      console.error('Error updating appointment:', error);
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/appointment">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Edit Appointment</h2>
          </div>
        </div>
      </header>

      {
        !formData ? 
        <Loader nav={false}/> :
        <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-group mb-3">
                <label className="form-label fw-semibold">Patient name</label>
                <input
                  type="text"
                  name="patientName"
                  className="form-control"
                  value={formData.patientName}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Address</label>
              <div className="form-input mb-3">
                <textarea
                  rows="5"
                  className="form-control"
                  value={formData.address}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Nurse In Charge</label>
              <div className="form-input mb-3">
                <select
                  name="nurse"
                  className="form-control"
                  value={nurse ? nurse.id : ''}
                  onChange={handleChange}
                >
                  <option value="">-- Please Select --</option>
                  {nurseList.map((nurse) => (
                    <option key={nurse.id} value={nurse.id}>{nurse.name} ({nurse.id})</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Service Date</label>
              <div className="form-input mb-3">
                <DatePicker
                  selected={serviceDate}
                  onChange={handleDateChange}
                  showTimeSelect
                  dateFormat="Pp"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Remark</label>
              <div className="form-input mb-3">
                <textarea
                  rows="5"
                  className="form-control"
                  value={formData.remark}
                  disabled
                />
              </div>
            </div>
            <button type="submit" className="btn theme-btn save-address w-100">Assign</button>
          </form>
        </div>
      </section>
      }
      
    </div>
  );
};

export default AdminEditAppointment;
