import { leaves } from "../firebaseCollection";
import { db } from '../../firebase/firebase';
import { collection, Timestamp, addDoc } from 'firebase/firestore';

export const nurseRequestLeave = async (date, reason, profile) => {
  try {
    const leaveCollection = collection(db, leaves);
    const leaveData = {
      date: Timestamp.fromDate(new Date(date)),
      reason,
      requested_at: Timestamp.now(),
      nurse_profile: profile,
      status: 'Pending'
    };
    const leaveDoc = await addDoc(leaveCollection, leaveData);
    return leaveDoc.id;
  } catch (error) {
    console.error('Error requesting leave:', error);
    throw error;
  }
};