import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getNurseAppointments } from '../../api/appointment';
import Loader from '../../components/Loader';

const CompletedAppointment = ({ appointments }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="tab-pane fade show active" id="completed-tab" role="tabpanel" aria-labelledby="pill-completed-tab">
      {!appointments || appointments.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
          <h4>No Data</h4>
        </div>
      ) : (
        <ul className="earning-list">
          {appointments.map((appointment, index) => (
            <li key={index}>
              <div className="earning-box">
                <div className="earning-img">
                  <img className="img-fluid earning-icon" src="/assets/images/svg/user.svg" alt="receipt" />
                </div>
                <div className="earning-content flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="light-text fw-medium">Patient Name</h6>
                    <h6 className="fw-normal light-text">{appointment.date} {appointment.time}</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h6 className="fw-mediun dark-text">{appointment.patient_name}</h6>
                  </div>
                </div>
              </div>
              <div className="earning-box mt-3">
                <div className="earning-img">
                  <img className="img-fluid earning-icon" src="/assets/images/svg/navigate_now.svg" alt="navigate" />
                </div>
                <div className="earning-content flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="light-text fw-medium">Patient Address</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h6 className="fw-mediun dark-text">{appointment.address}</h6>
                  </div>
                </div>
              </div>
              <div className="order-item-table">
                <table className="table table-borderless mb-0">
                  <thead>
                    <tr>
                      <th className="text-left"><img className="img-fluid earning-icon" src="/assets/images/svg/assessment.svg" alt="assessment" /> Patient's Assessment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left" colSpan="2">Blood Pressure (BP)</td>
                      <td className="text-end"><b>{appointment.blood}</b></td>
                    </tr>
                    <tr>
                      <td className="text-left" colSpan="2">Pulse Rate (PR)</td>
                      <td className="text-end"><b>{appointment.pulse}</b></td>
                    </tr>
                    <tr>
                      <td className="text-left" colSpan="2">Oxygen Saturation (SpO2)</td>
                      <td className="text-end"><b>{appointment.spo2}</b></td>
                    </tr>
                    <tr>
                      <td className="text-left" colSpan="2">Glucose Level (FBS/PPBS)</td>
                      <td className="text-end"><b>{appointment.glucose}</b></td>
                    </tr>
                    <tr>
                      <td colSpan="2">Service of the day: <b>{appointment.services}</b></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CompletedAppointment;
