import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { messaging, onMessage } from '../src/firebase/firebase';
import { ToastContainer, toast } from 'react-toastify';
import { requestNotificationPermission } from './api/notification';
import 'react-toastify/dist/ReactToastify.css';
import Main from './pages/Main';
import PatientLogin from './pages/patient/Login';
import NurseLogin from './pages/nurse/Login';
import PatientAppointment from './pages/patient/PatientAppointment';
import PatientProfile from './pages/patient/PatientProfile';
import HealthRecord from './pages/patient/HealthRecord';
import RequestAppointment from './pages/patient/RequestAppointment';
import PatientNotification from './pages/patient/PatientNotification';
import NurseHome from './pages/nurse/NurseHome';
import NurseAppointment from './pages/nurse/NurseAppointment';
import NurseProfile from './pages/nurse/NurseProfile';
import NurseLeave from './pages/nurse/NurseLeave';
import NurseNotification from './pages/nurse/NurseNotification';
import AdminLogin from './pages/admin/Login';
import AdminHome from './pages/admin/AdminHome';
import AdminProfile from './pages/admin/AdminProfile';
import AdminEditProfile from './pages/admin/AdminEditProfile';
import ManagePatient from './pages/admin/ManagePatient';
import ManageNurse from './pages/admin/ManageNurse';
import AdminCreateAppointment from './pages/admin/AdminCreateAppointment';
import InquiryList from './pages/admin/InquiryList';
import ManageLeave from './pages/admin/ManageLeave';
import AdminNotification from './pages/admin/AdminNotification';
import EditPatient from './pages/admin/EditPatient';
import EditNurse from './pages/admin/EditNurse';
import AdminAppointment from './pages/admin/AdminAppointment';
import AdminEditAppointment from './pages/admin/AdminEditAppointment';
import AddPatient from './pages/admin/AddPatient';
import AddNurse from './pages/admin/AddNurse';
import ProtectedRoute from './routes/ProtectedRoute';
import AuthProtectedRoute from './routes/AuthProtectedRoutes';

const authProtectedRoutes = [
  { path: "/patient/appointment", element: <PatientAppointment />, role: 'patient' },
  { path: "/patient/profile", element: <PatientProfile />, role: 'patient' },
  { path: "/patient/healthRecord", element: <HealthRecord />, role: 'patient' },
  { path: "/patient/requestAppointment/:patientId", element: <RequestAppointment />, role: 'patient' },
  { path: "/patient/notification", element: <PatientNotification />, role: 'patient' },
  { path: "/nurse/home", element: <NurseHome />, role: 'nurse' },
  { path: "/nurse/appointment", element: <NurseAppointment />, role: 'nurse' },
  { path: "/nurse/profile", element: <NurseProfile />, role: 'nurse' },
  { path: "/nurse/leave", element: <NurseLeave />, role: 'nurse' },
  { path: "/nurse/notification", element: <NurseNotification />, role: 'nurse' },
  { path: "/admin/home", element: <AdminHome />, role: 'admin' },
  { path: "/admin/profile", element: <AdminProfile />, role: 'admin' },
  { path: "/admin/edit-profile", element: <AdminEditProfile />, role: 'admin' },
  { path: "/admin/patient-list", element: <ManagePatient />, role: 'admin' },
  { path: "/admin/nurse-list", element: <ManageNurse />, role: 'admin' },
  { path: "/admin/create-appt", element: <AdminCreateAppointment />, role: 'admin' },
  { path: "/admin/inquiry-list", element: <InquiryList />, role: 'admin' },
  { path: "/admin/leave-list", element: <ManageLeave />, role: 'admin' },
  { path: "/admin/notification", element: <AdminNotification />, role: 'admin' },
  { path: "/admin/add-patient", element: <AddPatient />, role: 'admin' },
  { path: "/admin/add-nurse", element: <AddNurse />, role: 'admin' },
  { path: "/admin/edit-patient/:patientId", element: <EditPatient />, role: 'admin' },
  { path: "/admin/edit-nurse/:nurseId", element: <EditNurse />, role: 'admin' },
  { path: "/admin/appointment/:appointmentId", element: <AdminAppointment />, role: 'admin' },
  { path: "/admin/appointment", element: <AdminAppointment />, role: 'admin' },
  { path: "/admin/edit-appointment/:appointmentId", element: <AdminEditAppointment />, role: 'admin' },
];

function App() {

  useEffect(() => {
    const requestPermission = async () => {
      const result = await requestNotificationPermission();
      if (result === 'denied') {
        // Show a message to the user that notifications are disabled
        toast.error('Notification permission is denied. Please enable it in your browser settings.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (result) {
        // Token received, handle as needed
        //console.log('FCM Token:', result);
      }
    };

    requestPermission();
  }, []);

  useEffect(() => {
    // Handle foreground notifications
    const unsubscribe = onMessage(messaging, (payload) => {
      //console.log('Raw payload:', JSON.stringify(payload));
      // Check if the message is a data message
      if (payload.data) {
        console.log(payload.data.body);
        toast.info(payload.data.body, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (payload.notification) {
        console.log(payload.notification.body);
        toast.info(payload.notification.body, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            }
          />
          <Route exact path="/patient/login" element={<PatientLogin />} />
          <Route exact path="/nurse/login" element={<NurseLogin />} />
          <Route exact path="/admin/login" element={<AdminLogin />} />
          {authProtectedRoutes.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.path}
              element={
                <AuthProtectedRoute role={route.role}>
                  {route.element}
                </AuthProtectedRoute>
              }
            />
          ))}
        </Routes>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
