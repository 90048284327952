import { Link } from "react-router-dom";

const PatientNavbar = ({ activeLinks }) => (
    <div className="navbar-menu">
        <ul>
        <li className={`w-50 ${activeLinks.appointment ? "active" : ""}`}>
            <Link to="/patient/appointment" className="icon">
              <img className="unactive" src="/assets/images/svg/order.svg" alt="receipt" />
              <img className="active" src="/assets/images/svg/order_selected.svg" alt="receipt" />
              <span>Appointment</span>
            </Link>
          </li>
          <li className={`w-50 ${activeLinks.profile ? "active" : ""}`}>
            <Link to="/patient/profile" className="icon">
              <img className="unactive" src="/assets/images/svg/profile.svg" alt="user" />
              <img className="active" src="/assets/images/svg/profile_selected.svg" alt="user" />
              <span>Profile</span>
            </Link>
          </li>
        </ul>
      </div>
);

export default PatientNavbar;