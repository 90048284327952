import { getToken, messaging} from '../../firebase/firebase';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";

export const getPatientNotification = async () => {
  const patientId = localStorage.getItem('patient_nric');
  const notifications = [];
  const notificationsCollection = collection(db, `patients/${patientId}/notifications`);

  const snapshot = await getDocs(notificationsCollection);
  snapshot.forEach((doc) => {
    notifications.push({ id: doc.id, ...doc.data() });
  });

  return notifications;
};

export const getNurseNotification = async () => {
  const nurseId = localStorage.getItem('nurse_id');
  const notifications = [];
  const notificationsCollection = collection(db, `nurse/${nurseId}/notifications`);

  const snapshot = await getDocs(notificationsCollection);
  snapshot.forEach((doc) => {
    notifications.push({ id: doc.id, ...doc.data() });
  });

  return notifications;
};

export const requestNotificationPermission = async () => {
  if (Notification.permission === 'default') {
    try {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    } catch (error) {
      console.error('Notification permission request failed:', error);
      return false;
    }
  }
  return Notification.permission === 'granted';
};

export const getFCMToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
    if (currentToken) {
      //console.log('FCM Token:', currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
};

export const subscribeToTopic = async (topic) => {
  try {
    if (Notification.permission === 'granted') {
        const token = await getFCMToken();
        if (token) {
          const response = await fetch(process.env.REACT_APP_SUBSCRIBE_TOPIC_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ action: 'subscribe', token, topic }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const result = await response.text();
          //console.log('Subscription result:', result);
          return result;
      } else {
          throw new Error('No FCM token available');
      }
    }
  } catch (error) {
    console.error('Error subscribing to topic:', error);
    throw error;
  }
};

export const unsubscribeFromTopic = async (topic) => {
  try {
    if (Notification.permission === 'granted') {
      const token = await getFCMToken();
      if (token) {
        const response = await fetch(process.env.REACT_APP_SUBSCRIBE_TOPIC_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ action: 'unsubscribe', token, topic }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.text();
        //console.log('Unsubscription result:', result);
        return result;
      } else {
        throw new Error('No FCM token available');
      }
    }
  } catch (error) {
    console.error('Error unsubscribing from topic:', error);
    throw error;
  }
};

export const sendNotificationToTopic = async (topic, title, body) => {
  try {
    if (Notification.permission === 'granted') {
      const response = await fetch(process.env.REACT_APP_SUBSCRIBE_TOPIC_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'sendNotification', topic, title, body }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.text();
      console.log('Notification sent:', result);
      return result;
    }
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
};