import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const patientId = localStorage.getItem('patient_id');
  const nurseId = localStorage.getItem('nurse_id');
  const adminId = localStorage.getItem('admin_id');

  if (patientId) {
    return <Navigate to="/patient/appointment" />;
  }

  if (nurseId) {
    return <Navigate to="/nurse/home" />;
  }

  return children;
};

export default ProtectedRoute;