import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createPatient } from '../../api/admin';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';
import { removeDashes } from '../../helpers';

const AddPatient = () => {
  const navigate = useNavigate();

  const [patientName, setPatientName] = useState('');
  const [nric, setNric] = useState('');
  const [nricWithDash, setNricWithDash] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [status, setStatus] = useState('Active');
  const [address, setAddress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePatientNameChange = (event) => {
    setPatientName(event.target.value);
  };

  const handleNricChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedValue = '';

    // Format the input value
    if (value.length > 6) {
      formattedValue += value.substr(0, 6) + '-';
      if (value.length > 8) {
        formattedValue += value.substr(6, 2) + '-';
        formattedValue += value.substr(8, 4);
      } else {
        formattedValue += value.substr(6);
      }
    } else {
      formattedValue = value;
    }

    setNric(removeDashes(e.target.value));
    setNricWithDash(formattedValue);
  };

  const handleContactNoChange = (event) => {
    setContactNo(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const validateForm = () => {
    if (!patientName || !nric || !contactNo || !address || !status) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'All fields are required.',
        confirmButtonColor: '#008893',
      });
      return false;
    }

    if (nric.length !== 12) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'NRIC must be exactly 12 digits.',
        confirmButtonColor: '#008893',
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    Swal.fire({
      title: 'Adding...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      // Perform geocoding
      // const response = await fetch('http://localhost:5000/geocode', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({ address })
      // });
      // const data = await response.json();
      // if (!response.ok) throw new Error(data.error || 'Geocoding failed');

      // const { latitude, longitude } = data;

      await createPatient({
        patient_name: patientName,
        patient_nric: nric,
        contact_no: contactNo,
        status: status,
        address: address,
      });

      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Patient added successfully.',
        confirmButtonColor: '#008893',
      }).then(() => {
        navigate('/admin/patient-list');
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'There was an error adding the patient.',
        confirmButtonColor: '#008893',
      });
    }
  };

  if (isSubmitting) {
    return <Loader />;
  }

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/patient-list">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Add Patient</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-group mb-3">
                <label className="form-label fw-semibold">Patient Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={patientName}
                  onChange={handlePatientNameChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">NRIC</label>
              <div className="form-input mb-3">
                <input
                  type="text"
                  id="nricinput"
                  className="form-control"
                  maxLength="14"
                  value={nricWithDash}
                  onChange={handleNricChange}
                />
                <input type="hidden" id="actualic" name="actualic" value={nric} />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Contact No</label>
              <div className="form-input mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={contactNo}
                  onChange={handleContactNoChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Address</label>
              <div className="form-input mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={handleAddressChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Status</label>
              <div className="form-input mb-3">
                <select name="status" className="form-control" value={status} onChange={handleStatusChange}>
                  <option value="Active">Active</option>
                  <option value="Suspend">Suspend</option>
                </select>
              </div>
            </div>
            <button type="submit" className="btn theme-btn save-address w-100">
              Save
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AddPatient;
