import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { nurseLogin } from '../../api/auth';
import Swal from "sweetalert2";
import { getNurseProfile } from '../../api/profile';
import { sendNotificationToTopic, subscribeToTopic, requestNotificationPermission } from '../../api/notification';

const NurseLogin = () => {
  const [nuid, setNuid] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};
    if (!nuid) {
      errors.nuid = 'NUID is required';
    }
    if (!password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const login = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const data = await nurseLogin(nuid, password); // Replace this with your actual login logic
      if (data.status === 400) {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Login",
          text: data.message,
          confirmButtonColor: "#008893"
        });
      }
      else {
        const nurseProfile = await getNurseProfile(nuid);
        if (nurseProfile && nurseProfile.status === 'Suspend') {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Account Suspended",
            text: "Your account has been suspended. Please contact support for more information.",
            confirmButtonColor: "#008893"
          });
        } else {
            await subscribeToTopic('everyone');
            await subscribeToTopic('nurse');
            await subscribeToTopic(`nurse_${nuid}`);
            //await sendNotificationToTopic(`nurse_${nuid}`, 'Login', 'You have login successfully');
            Swal.close();
            navigate('/nurse/home');
        }
      }
    } catch (error) {
      console.error('Login failed:', error);
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Login",
        text: error,
        confirmButtonColor: "#008893"
      });
    }
  };

  return (
    <section className="section-b-space pt-0">
      <img className="img-fluid login-img restaurant-login-img auth-img"
        src="/assets/images/login-screen.png" alt="Clinics" />

      <div className="custom-container">
        <form className="auth-form mt-4" onSubmit={login}>
          <h2>Please enter your NUID</h2>

          <div className="form-group mt-4">
            <label className="form-label fw-semibold dark-text">NUID</label>
            <div className="d-flex gap-3">
              <div className="form-input dark-border-gradient">
                <input 
                  type="text" 
                  className="form-control" 
                  id="nuid" 
                  placeholder="BK001" 
                  value={nuid}
                  onChange={(e) => setNuid(e.target.value)}
                />
              </div>
            </div>
            {errors.nuid && <span className="text-danger">{errors.nuid}</span>}
          </div>
          <div className="form-group mt-4">
            <label className="form-label fw-semibold dark-text">Password</label>
            <div className="d-flex gap-3">
              <div className="form-input dark-border-gradient">
                <input 
                  type="password" 
                  className="form-control" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            {errors.password && <span className="text-danger">{errors.password}</span>}
          </div>
          
          <button type="submit" className="btn theme-btn w-100 mt-4">Login</button>
        </form>
        <p className="text-center">By continuing, you agree to our Terms of Service, Privacy Policy, and Content Policy</p>
      </div>
    </section>
  );
}

export default NurseLogin;
