import axios from "axios";
import { uLogin, nLogin } from "../apiConfig";
import { nurse } from "../firebaseCollection";
import bcrypt from "bcryptjs";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";


// export const patientLogin = async (nric) => {
//     try {
//       const response = await axios.post(uLogin, {
//         patient_nric: nric
//       });

//       if (response.data.status === 200) {
//         localStorage.clear();
//         localStorage.setItem("patient_id", response.data.user_id);
//         localStorage.setItem("patient_nric", nric);
//       }
//       return response.data;
//     } catch (error) {
//       throw error;
//     }
// };

export const nurseLogin = async (username, password) => {
  try {
    const nurseDoc = await getDoc(doc(db, nurse, username));

    if (!nurseDoc.exists()) {
      return { status: 400, message: 'Invalid NUID or password' };
    }

    const nurseData = nurseDoc.data();

    const isPasswordValid = await bcrypt.compare(password, nurseData.password);

    if (!isPasswordValid) {
      return { status: 400, message: 'Invalid NUID or password' };
    }

    localStorage.clear();
    localStorage.setItem("nurse_id", nurseData.employee_code);
    return { status: 200, message: 'Login successful', data: nurseData };
  } catch (error) {
    console.error('Error during nurse login:', error);
    throw error;
  }
};


export const adminRegister = async (username, password, email) => {
  try {
    const response = await axios.post(process.env.REACT_APP_AUTH_URL, {
      action: 'register',
      username,
      password,
      email,
      isAdmin: true
    });

    if (response.data.status === 201) {
      return { status: 201, message: 'Admin registered successfully' };
    }
    return response.data;
  } catch (error) {
    console.error('Error during admin registration:', error);
    throw error;
  }
};

export const adminLogin = async (username, password, encryption) => {
  try {
    const response = await axios.post(process.env.REACT_APP_AUTH_URL, {
      action: 'login',
      username,
      password,
      isAdmin: true,
      encryption: encryption
    });

    //console.log(response);
    if (response.status === 200) {
      //console.log(response.data);
      localStorage.clear();
      localStorage.setItem("admin_id", response.data.data.admin_id);
      localStorage.setItem("admin_username", response.data.data.username);
      localStorage.setItem("admin_display_name", response.data.data.display_name);
      return { 
        status: 200, 
        message: 'Login successful', 
        data: response.data.data 
      };
    }
    return response.data;
  } catch (error) {
    console.error('Error during admin login:', error);
    throw error;
  }
};

export const adminChangePassword = async (username, oldPassword, newPassword, encryption) => {
  try {
    const response = await axios.post(process.env.REACT_APP_AUTH_URL, {
      action: 'changePassword',
      username,
      oldPassword,
      newPassword,
      encryption,
      isAdmin: true
    });

    //console.log(response);
    if (response.status === 200) {
      return { status: 200, message: 'Password changed successfully' };
    }
    return response.data;
  } catch (error) {
    console.error('Error during admin password change:', error);
    throw error;
  }
};