import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getNurseAppointments, appointmentCheckin } from '../../api/appointment';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';

const UpcomingAppointment = ({ appointments }) => {
  const [isLoaded, setIsLoaded] = useState(true);
  
  const handleCheckin = async (appointmentId, appointment) => {
    console.log(appointment);
    try {
      Swal.fire({
        title: 'Checking In...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await appointmentCheckin(appointmentId, appointment);
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Appointment status updated to Ongoing.',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      setIsLoaded(true);
      console.error('Error during check-in: ', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to update the appointment status. Please try again.',
        confirmButtonText: 'Close'
      });
    }
  };

  return (
    <div className="tab-pane fade show active" id="upcoming-tab" role="tabpanel" aria-labelledby="pill-upcoming-tab">
      {!appointments || appointments.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
          <h4>No Data</h4>
        </div>
      ) : (
        <ul className="earning-list">
          {appointments.map((appointment, index) => (
            <li key={index}>
              <div className="earning-box">
                <div className="earning-img">
                  <img className="img-fluid earning-icon" src="/assets/images/svg/user.svg" alt="receipt" />
                </div>
                <div className="earning-content flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="light-text fw-medium">Patient Name</h6>
                    <h6 className="fw-normal light-text">{appointment.date} {appointment.time}</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h6 className="fw-mediun dark-text">{appointment.patient_name}</h6>
                  </div>
                </div>
              </div>
              <div className="earning-box mt-3">
                <div className="earning-img">
                  <img className="img-fluid earning-icon" src="/assets/images/svg/navigate_now.svg" alt="navigate" />
                </div>
                <div className="earning-content flex-column">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="light-text fw-medium">Patient Address</h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <h6 className="fw-mediun dark-text">{appointment.address}</h6>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center gap-3">
                <a href={`https://www.google.com/maps/search/?api=1&query=${appointment.address}`} className="btn theme-btn w-100 mt-3">Navigate</a>
                <button onClick={() => handleCheckin(appointment.id, appointment)} className="btn patient-btn w-100 mt-3">Check-in</button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UpcomingAppointment;
