import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getPatientProfile, updatePatient } from '../../api/admin';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';

const EditPatient = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();

  const [patientName, setPatientName] = useState('');
  const [nric, setNric] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [status, setStatus] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const patientData = await getPatientProfile(patientId);
        if (patientData) {
          setPatientName(patientData.patient_name);
          setNric(patientData.patient_nric);
          setContactNo(patientData.contact_no);
          setStatus(patientData.status);
        }
      } catch (error) {
        console.error('Error fetching patient:', error);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchPatient();
  }, [patientId]);

  const handlePatientNameChange = (event) => {
    setPatientName(event.target.value);
  };

  const handleNricChange = (event) => {
    setNric(event.target.value);
  };

  const handleContactNoChange = (event) => {
    setContactNo(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Updating...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await updatePatient(patientId, {
        patient_name: patientName,
        patient_nric: nric,
        contact_no: contactNo,
        status: status,
      });
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Patient updated successfully.',
        confirmButtonColor: '#008893',
      }).then(() => {
        navigate('/admin/patient-list');
      });
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error updating the patient.',
        confirmButtonColor: '#008893',
      });
    }
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/patient-list">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Edit Patient</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-group mb-3">
                <label className="form-label fw-semibold">Patient Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={patientName}
                  onChange={handlePatientNameChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">NRIC</label>
              <div className="form-input mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={nric}
                  onChange={handleNricChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Contact No</label>
              <div className="form-input mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={contactNo}
                  onChange={handleContactNoChange}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Status</label>
              <div className="form-input mb-3">
                <select name="status" className="form-control" value={status} onChange={handleStatusChange}>
                  <option value="Active">Active</option>
                  <option value="Suspend">Suspend</option>
                </select>
              </div>
            </div>
            <button type="submit" className="btn theme-btn save-address w-100">
              Save
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default EditPatient;
