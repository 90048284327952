import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';


const Main = () => {
    return (
      <div className="App">
        <section className="home">
          <video autoPlay muted loop className="intro-video">
            <source src="assets/images/mainvid.mp4" type="video/mp4" />
          </video>

          <div className="theme-content">
            <div className="custom-container bg-transparent">
              <ul className="slogan">
                <li></li>
                <li>Quality nursing at your doorstep</li>
                <li></li>
              </ul>
              <p className="copyright">BACKLINERS © 2024</p>
              <div className='button-container'>
              <Link to="/patient/login" className="btn patient-btn spaced-button" role="button">
                <img className="img-fluid icon ms-auto mb-0" src="assets/images/svg/patients.svg" alt="box" /> I am Patient
              </Link>
              <Link to="/nurse/login" className="btn theme-btn start-btn spaced-button" role="button">
                <img className="img-fluid icon ms-auto mb-0" src="assets/images/svg/nurses.svg" alt="box" /> I am Nurse
              </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Main;