import React, { useState } from 'react';
import { getPatientProfile } from '../../api/admin';
import { useNavigate } from 'react-router-dom';
import { removeDashes } from '../../helpers';
import Swal from "sweetalert2";
import { subscribeToTopic, sendNotificationToTopic } from '../../api/notification';

const PatientLogin = () => {
  const navigate = useNavigate();
  const [nric, setNric] = useState('');

  const login = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const patientProfile = await getPatientProfile(removeDashes(nric));
        if (patientProfile && patientProfile.status === 'Suspend') {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Account Suspended",
            text: "Your account has been suspended. Please contact support for more information.",
            confirmButtonColor: "#008893"
          });
        } else {
          localStorage.clear();
          localStorage.setItem("patient_id", removeDashes(nric));
          localStorage.setItem("patient_nric", removeDashes(nric));
          await subscribeToTopic('everyone');
          await subscribeToTopic('patients');
          await subscribeToTopic(`patient_${removeDashes(nric)}`);
          //await sendNotificationToTopic(`patient${removeDashes(nric)}`, 'Login', 'You have login successfully');
          Swal.close();
          navigate('/patient/appointment');
        }
    } catch (error) {
      console.error('Login failed:', error);
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Login",
        text: error.message,
        confirmButtonColor: "#008893"
      });
    }
  };

  const handleNricChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    let formattedValue = '';

    // Format the input value
    if (value.length > 6) {
      formattedValue += value.substr(0, 6) + '-';
      if (value.length > 8) {
        formattedValue += value.substr(6, 2) + '-';
        formattedValue += value.substr(8, 4);
      } else {
        formattedValue += value.substr(6);
      }
    } else {
      formattedValue = value;
    }

    setNric(formattedValue);
  };

  return (
    <div className="App">
      <section className="section-b-space pt-0">
        <img className="img-fluid login-img restaurant-login-img auth-img"
          src="/assets/images/login-screen.png" alt="Clinics" />

        <div className="custom-container">
          <form className="auth-form mt-4">
            <h2>Please enter your 12 digit NRIC</h2>

            <div className="form-group mt-4">
              <div className="d-flex gap-3">
                <div className="form-input dark-border-gradient">
                  <input type="text" id="nricinput" className="form-control" maxLength="14" value={nric} onChange={handleNricChange} />
                  <input type="hidden" id="actualic" name="actualic" />
                </div>
              </div>
            </div>
            <button className="btn theme-btn w-100 mt-4" onClick={login} role="button">Login</button>
          </form>
          <p className="text-center">By continue, you agree to our Terms of service Privacy Policy Content Policy</p>
        </div>
      </section>
    </div>
  );
}

export default PatientLogin;
