import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPatientProfile } from '../../api/profile';
import { Timestamp } from 'firebase/firestore';

// Helper function to convert Firestore GeoPoint to a plain object
const geoPointToObject = (geoPoint) => ({
  latitude: geoPoint._lat,
  longitude: geoPoint._long,
});

// Helper function to convert Firestore Timestamp to a plain object
const timestampToObject = (timestamp) => ({
  seconds: timestamp.seconds,
  nanoseconds: timestamp.nanoseconds,
});

// Helper function to recursively convert all Timestamps in an object to plain objects
const convertTimestamps = (obj) => {
  if (obj instanceof Timestamp) {
    return timestampToObject(obj);
  }
  
  if (Array.isArray(obj)) {
    return obj.map(convertTimestamps);
  }
  
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = convertTimestamps(obj[key]);
      return acc;
    }, {});
  }
  
  return obj;
};

// Async thunk to fetch profile data
export const fetchProfile = createAsyncThunk('profile/fetchProfile', async () => {
  const response = await getPatientProfile();

  // Convert any GeoPoint fields to plain objects
  if (response.geopoint) {
    response.geopoint = geoPointToObject(response.geopoint);
  }

  // Convert any Timestamp fields to plain objects
  const convertedResponse = convertTimestamps(response);

  return convertedResponse;
});

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default profileSlice.reducer;
