import { Link } from "react-router-dom";

const NurseNavbar = ({ activeLinks }) => (
    <div className="navbar-menu">
        <ul>
        <li className={`w-25 ${activeLinks.home ? "active" : ""}`}>
            <Link to="/nurse/home" className="icon">
              <img className="unactive" src="/assets/images/svg/home.svg" alt="home" />
              <img className="active" src="/assets/images/svg/home_selected.svg" alt="home" />
              <span>Home</span>
            </Link>
          </li>
          <li className={`w-25 ${activeLinks.appointment ? "active" : ""}`}>
            <Link to="/nurse/appointment" className="icon">
              <img className="unactive" src="/assets/images/svg/order.svg" alt="receipt" />
              <img className="active" src="/assets/images/svg/order_selected.svg" alt="receipt" />
              <span>Appointment</span>
            </Link>
          </li>
          <li className={`w-25 ${activeLinks.profile ? "active" : ""}`}>
            <Link to="/nurse/profile" className="icon">
              <img className="unactive" src="/assets/images/svg/profile.svg" alt="user" />
              <img className="active" src="/assets/images/svg/profile_selected.svg" alt="user" />
              <span>Profile</span>
            </Link>
          </li>
        </ul>
    </div>
);

export default NurseNavbar;