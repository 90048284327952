import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase'; // Adjust this import path as needed
import AdminNavbar from '../../components/AdminNavbar';
import Swal from 'sweetalert2';

const AdminProfile = () => {
  const [totalNurses, setTotalNurses] = useState(0);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalPendingApprove, setTotalPendingApprove] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const nursesSnapshot = await getDocs(collection(db, 'nurse'));
        const patientsSnapshot = await getDocs(collection(db, 'patients'));
        const pendingApproveSnapshot = await getDocs(collection(db, 'leaves'), where('status', '==', 'Pending'));

        setTotalNurses(nursesSnapshot.size);
        setTotalPatients(patientsSnapshot.size);
        setTotalPendingApprove(pendingApproveSnapshot.size);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching counts:', error);
        setIsLoaded(true);
      }
    };

    fetchCounts();
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: 'Logging out...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    localStorage.clear();
    Swal.close();
    navigate('/admin/login');
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <a href="#" style={{ display: 'none' }}>
            </a>
            <div className="head-content">
              <div className="text-center">
                <h2>BACKLINERS - DIRECTOR</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section>
        <div className="custom-container">
          <div className="profile-cover">
            <img className="img-fluid profile-pic" src="/assets/images/svg/B_blue.svg" alt="profile" />
          </div>
          <div className="profile-name">
            <h5>Warren Beh</h5>
          </div>
        </div>
      </section>

      <section>
        <div className="custom-container">
          <ul className="profile-details-list">
            <li>
              <h2>{isLoaded ? totalNurses : '0'}</h2>
              <h6>Total Nurses</h6>
            </li>
            <li>
              <h2>{isLoaded ? totalPatients : '0'}</h2>
              <h6>Total Patients</h6>
            </li>
            <li>
              <h2>{isLoaded ? totalPendingApprove : '0'}</h2>
              <h6>Pending Approval</h6>
            </li>
          </ul>

          <ul className="profile-list">
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/profile_blue.svg" alt="leave" />
                </div>
                <Link to="/admin/edit-profile" className="profile-box-name">
                  <h5>Manage Profile</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/patient_blue.svg" alt="leave" />
                </div>
                <Link to="/admin/patient-list" className="profile-box-name">
                  <h5>Manage Patient</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/nurses_blue.svg" alt="leave" />
                </div>
                <Link to="/admin/nurse-list" className="profile-box-name">
                  <h5>Manage Nurse</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/applyleave.svg" alt="leave" />
                </div>
                <Link to="/admin/create-appt" className="profile-box-name">
                  <h5>Create Appointment</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/inquiry_blue.svg" alt="leave" />
                </div>
                <Link to="/admin/inquiry-list" className="profile-box-name">
                  <h5>Manage Inquiry</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/leave_blue.svg" alt="leave" />
                </div>
                <Link to="/admin/leave-list" className="profile-box-name">
                  <h5>Manage Leave</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/notification_blue.svg" alt="notification" />
                </div>
                <Link to="/admin/notification" className="profile-box-name">
                  <h5>Manage Notification</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </Link>
              </div>
            </li>
            <li>
              <div className="profile-box">
                <div className="profile-icon">
                  <img className="img-fluid icon" src="/assets/images/svg/logout_blue.svg" alt="logout" />
                </div>
                <button onClick={handleLogout} className="profile-box-name btn btn-link" style={{ padding: 0 }}>
                  <h5>Log Out</h5>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="panel-space"></section>

      <AdminNavbar activeLinks={{ home: false, appointment: false, profile: true }} />
    </div>
  );
};

export default AdminProfile;
