import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateT } from '../../helpers';

const AdminCompletedAppointment = ({ appointments }) => {
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [startDate, setStartDate] = useState(firstDayOfMonth);
  const [endDate, setEndDate] = useState(today);
  const [filteredAppointments, setFilteredAppointments] = useState(appointments);

  useEffect(() => {
    filterAppointments();
  }, [appointments, startDate, endDate]);

  const filterAppointments = () => {
    const filtered = appointments.filter((appointment) => {
      const appointmentDate = new Date(appointment.checkout.seconds * 1000);
      if (startDate && endDate) {
        return appointmentDate >= startDate && appointmentDate <= endDate;
      } else if (startDate) {
        return appointmentDate >= startDate;
      } else if (endDate) {
        return appointmentDate <= endDate;
      }
      return true;
    });

    setFilteredAppointments(filtered);
  };

  return (
    <section className="section-b-space">
      <div className="custom-container">
        <div className="form-group d-flex gap-5 mt-4">
          <div className="d-flex gap-3">
            <div className="form-input dark-border-gradient">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={today}
                placeholderText="Start Date"
                className="form-control"
              />
            </div>
          </div>
          to
          <div className="d-flex gap-3">
            <div className="form-input dark-border-gradient">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                maxDate={today}
                placeholderText="End Date"
                className="form-control"
              />
            </div>
          </div>
        </div>
        {!filteredAppointments || filteredAppointments.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
            <h4>No Data</h4>
          </div>
        ) : (
          <ul className="earning-list">
            {filteredAppointments.map((appointment, index) => (
              <li key={index}>
                <div className="earning-box">
                  <div className="earning-img">
                    <img className="img-fluid earning-icon" src="/assets/images/svg/user.svg" alt="receipt" />
                  </div>
                  <div className="earning-content flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="light-text fw-medium">Patient Name</h6>
                      <h6 className="fw-normal light-text">{formatDateT(appointment.checkout)}</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h6 className="fw-mediun dark-text">{appointment.patient_name}</h6>
                    </div>
                  </div>
                </div>
                <div className="earning-box mt-3">
                  <div className="earning-img">
                    <img className="img-fluid earning-icon" src="/assets/images/svg/navigate_now.svg" alt="receipt" />
                  </div>
                  <div className="earning-content flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6 className="light-text fw-medium">Patient Address</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <h6 className="fw-mediun dark-text">{appointment.address}</h6>
                    </div>
                  </div>
                </div>
                <div className="order-item-table">
                  <table className="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th className="text-left">
                          <img className="img-fluid earning-icon" src="/assets/images/svg/assessment.svg" alt="assessment" /> Patient's Assessment
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left" colSpan="2">Blood Pressure (BP)</td>
                        <td className="text-end"><b>{appointment.blood}</b></td>
                      </tr>
                      <tr>
                        <td className="text-left" colSpan="2">Pulse Rate (PR)</td>
                        <td className="text-end"><b>{appointment.pulse}</b></td>
                      </tr>
                      <tr>
                        <td className="text-left" colSpan="2">Oxygen Saturation (SpO2)</td>
                        <td className="text-end"><b>{appointment.spo2}</b></td>
                      </tr>
                      <tr>
                        <td className="text-left" colSpan="2">Glucose Level (FBS/PPBS)</td>
                        <td className="text-end"><b>{appointment.glucose}</b></td>
                      </tr>
                      <tr>
                        <td colSpan="2">Service of the day: <b>{appointment.services}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default AdminCompletedAppointment;
