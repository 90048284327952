import { appointments, patients, assessments, nurse } from "../firebaseCollection";
import { db } from '../../firebase/firebase';
import { collection, getDoc, increment, setDoc, getDocs, query, where, doc, runTransaction, updateDoc, Timestamp, deleteDoc } from 'firebase/firestore';

export const getPatientAppointments = async () => {
  const patient_nric = localStorage.getItem('patient_nric');
  const appointmentsCollection = collection(db, appointments);
  const q = query(appointmentsCollection, where('patient_nric', '==', patient_nric));

  try {
    const querySnapshot = await getDocs(q);
    const dataList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return dataList;
  } catch (error) {
    console.error('Error fetching appointments: ', error);
    throw error;
  }
};

export const getNurseAppointments = async (status) => {
  const nurse_id = localStorage.getItem('nurse_id');
  const appointmentsCollection = collection(db, appointments);
  const q = query(
    appointmentsCollection,
    where('nurse_id', '==', nurse_id),
    where('status', '==', status)
  );

  try {
    const querySnapshot = await getDocs(q);
    const dataList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return dataList;
  } catch (error) {
    console.error('Error fetching appointments: ', error);
    throw error;
  }
};

export const incrementCounter = async (counterDocId) => {
  const counterDocRef = doc(db, 'counters', counterDocId);

  try {
    const newCounterValue = await runTransaction(db, async (transaction) => {
      const counterDoc = await transaction.get(counterDocRef);

      if (!counterDoc.exists()) {
        throw new Error('Counter document does not exist!');
      }

      const currentCount = counterDoc.data().current;
      const newCount = currentCount + 1;
      transaction.update(counterDocRef, { current: newCount });

      return newCount;
    });

    return newCounterValue;
  } catch (error) {
    console.error('Transaction failed: ', error);
    throw error;
  }
};

export const appointmentCheckin = async (appointmentId, appointment) => {
  const appointmentRef = doc(db, 'appointments', appointmentId);
  const checkInTimestamp = Timestamp.now();
  
  try {
    const patientRef = doc(db, patients, appointment.patient_nric);

    const patientDoc = await getDoc(patientRef);
    if (!patientDoc.exists()) {
      throw new Error('Patient not found');
    }

    await updateDoc(appointmentRef, {
      status: 'Ongoing',
      checkin: checkInTimestamp
    });
    console.log('Appointment status updated to Ongoing');

    const patientData = patientDoc.data();
    let upcomingVisit = patientData.upcoming_visit;

    if (upcomingVisit && upcomingVisit.appt_id === parseInt(appointmentId, 10)) {
      upcomingVisit = null;
    }

    const lastVisit = {
      appt_id: appointmentId,
      date: checkInTimestamp
    };

    await updateDoc(patientRef, {
      last_visit: lastVisit,
      upcoming_visit: upcomingVisit,
      total_visit: increment(1)
    });

    console.log('Patient last visit and upcoming visit updated');
  } catch (error) {
    console.error('Error updating appointment status: ', error);
    throw error;
  }
};

export const createAppointment = async (appointmentData) => {
  // Increment the counter and get the new ID
  const newId = await incrementCounter(appointments);
  try {
    const appointmentsCollection = collection(db, appointments);
    const appointmentDocRef = doc(appointmentsCollection, newId.toString());

    // Ensure all date fields are converted to Firestore Timestamp
    const appointmentWithTimestamps = {
      ...appointmentData,
      appt_id: newId,

    };

    await setDoc(appointmentDocRef, appointmentWithTimestamps);
    console.log('Appointment created with ID: ', newId);

    const patientRef = doc(db, patients, appointmentData.patient_nric);
    const upcomingVisit = {
      appt_id: newId,
      date: appointmentData.appt_date
    }

    await updateDoc(patientRef, {
      upcoming_visit: upcomingVisit,
    });

    if (appointmentData.nurse_id != '') {
      const nurseRef = doc(db, nurse, appointmentData.nurse_id);
      await updateDoc(nurseRef, {
        upcoming_service: upcomingVisit,
      });
    }
    

    return newId;
  } catch (error) {
    console.error('Error creating appointment: ', error);
    throw error;
  }
};

export const cancelAppointment = async (appointmentId) => {
  const appointmentRef = doc(db, appointments, appointmentId);

  try {
    await deleteDoc(appointmentRef);
    console.log('Appointment deleted successfully');
  } catch (error) {
    console.error('Error deleting appointment: ', error);
    throw error;
  }
};

export const getAppointmentById = async (appointmentId) => {
  try {
    const docRef = doc(db, appointments, appointmentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    throw error;
  }
};

export const updateAppointment = async (appointmentId, updatedData) => {
  const appointmentDocRef = doc(db, appointments, appointmentId);
  await updateDoc(appointmentDocRef, updatedData);
  const upcomingVisit = {
    appt_id: appointmentId,
    date: updatedData.appt_date
  }
  const nurseRef = doc(db, nurse, updatedData.nurse_id);
  await updateDoc(nurseRef, {upcoming_service: upcomingVisit,});
};

export const nurseCheckout = async (appointmentId, assessmentData, appointment) => {
  const appointmentRef = doc(db, appointments, appointmentId);
  const checkoutTimestamp = Timestamp.now();

  try {
    const nurseRef = doc(db, nurse, appointment.nurse_id);

    const nurseDoc = await getDoc(nurseRef);
    if (!nurseDoc.exists()) {
      throw new Error('Nurse not found');
    }

    await updateDoc(appointmentRef, {
      ...assessmentData,
      status: 'Completed',
      checkout: checkoutTimestamp,
    });
    console.log('Appointment status updated to Completed');

    const nurseData = nurseDoc.data();
    let upcomingService = nurseData.upcoming_service;

    console.log(upcomingService);
    if (upcomingService != null) {
      if (upcomingService && upcomingService.appt_id === parseInt(appointmentId, 10)) {
        upcomingService = null;
      }
    }

    const lastService = {
      appt_id: appointmentId,
      date: checkoutTimestamp
    };

    await updateDoc(nurseRef, {
      last_service: lastService,
      upcoming_service: upcomingService ?? null,
      total_service: increment(1)
    });

    console.log('Nurse last service and upcoming service updated');

  } catch (error) {
    console.error('Error updating appointment status: ', error);
    throw error;
  }
};

export const updatePatientAssessment = async (appt_id, patientId, assessmentData) => {
  const assessmentsRef = collection(db, `patients/${patientId}/assessments`);
  const assessmentsDocRef = doc(assessmentsRef, appt_id);

  try {
    await setDoc(assessmentsDocRef, {
      ...assessmentData,
      appt_id: appt_id,
      assessment_date: Timestamp.now()
    });
    console.log('Assessment status added successfully');
  } catch (error) {
    console.error('Error adding assessment status: ', error);
    throw error;
  }
};