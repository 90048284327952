import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

export const getPatientHealthRecords = async (patientId) => {
  const assessmentsRef = collection(db, `patients/${patientId}/assessments`);

  try {
    const q = query(assessmentsRef);
    const querySnapshot = await getDocs(q);
    const assessments = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    return assessments;
  } catch (error) {
    console.error('Error fetching patient assessments: ', error);
    throw error;
  }
};