import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { createAppointment } from '../../api/appointment';
import { getNurseList, getPatientList } from '../../api/admin';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader';

const AdminCreateAppointment = () => {
  const [address, setAddress] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [nurseList, setNurseList] = useState([]);
  const [patient, setPatient] = useState('');
  const [nurse, setNurse] = useState('');
  const [serviceDate, setServiceDate] = useState(new Date());
  const [status, setStatus] = useState('Upcoming');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const patientsList = await getPatientList();
        setPatientList(patientsList);

        const nursesList = await getNurseList();
        setNurseList(nursesList);
      } catch (error) {
        console.error('Failed to fetch lists:', error);
      }
    };

    fetchLists();
  }, []);

  const handlePatientChange = (event) => {
    const patientId = event.target.value;
    const selectedPatient = patientList.find(p => p.id === patientId);
    setAddress(selectedPatient.address);
    setPatient(selectedPatient);
  };

  const handleNurseChange = (event) => {
    const nurseId = event.target.value;
    const selectedNurse = nurseList.find(n => n.id === nurseId);
    console.log(selectedNurse);
    setNurse(selectedNurse);
  };

  const handleServiceDateChange = (date) => {
    setServiceDate(date);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Prepare the data for Firestore
    const appointmentData = {
      patient_name: patient.patient_name,
      patient_nric: patient.patient_nric,
      address: address,
      nurse_name: nurse.name,
      nurse_id: nurse.id,
      status: status,
      appt_date: Timestamp.fromDate(new Date(serviceDate)),
      // Add other necessary fields
      blood: '', // Example field, replace with actual form fields if needed
      pulse: '', // Example field, replace with actual form fields if needed
      spo2: '', // Example field, replace with actual form fields if needed
      glucose: '', // Example field, replace with actual form fields if needed
      services: '', // Example field, replace with actual form fields if needed
      date_added: Timestamp.now(), // Current timestamp for date added
    };

    try {
      await createAppointment(appointmentData);
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Appointment created successfully.',
        confirmButtonText: 'OK'
      }).then(() => {
        navigate('/admin/appointment'); // Navigate to appointments page after closing the modal
      });
    } catch (error) {
      console.error('Failed to create appointment:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Failed to create the appointment. Please try again.',
        confirmButtonText: 'Close'
      });
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Create Appointment</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          {loading && (
            <Loader isNav={false} />
          )}
          {!loading && (
            <form className="auth-form profile-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">Patient (select2)</label>
                  <select name="patient" className="form-control" value={patient.id || ''} onChange={handlePatientChange}>
                    <option value="" disabled>Select a patient</option>
                    {patientList.map((patient) => (
                      <option key={patient.id} value={patient.id}>
                        {patient.patient_name} ({patient.patient_nric})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Address</label>
                <div className="form-input mb-3">
                  <textarea
                    rows="5"
                    className="form-control"
                    value={address}
                    readOnly={true}
                  ></textarea>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Nurse In Charge (select2)</label>
                <div className="form-input mb-3">
                  <select name="nurse" className="form-control" value={nurse.id || ''} onChange={handleNurseChange}>
                    <option value="" disabled>Select a nurse</option>
                    {nurseList.map((nurse) => (
                      <option key={nurse.id} value={nurse.id}>
                        {nurse.name} ({nurse.id})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Service Date and Time : </label>
                <DatePicker
                  selected={serviceDate}
                  onChange={handleServiceDateChange}
                  showTimeSelect
                  dateFormat="Pp"
                  className="form-control"
                />
              </div>
              <button type="submit" className="btn theme-btn save-address w-100">
                Create
              </button>
            </form>
          )}
        </div>
      </section>
    </div>
  );
};

export default AdminCreateAppointment;
