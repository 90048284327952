import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { getNurseList } from '../../api/admin';
import Loader from '../../components/Loader';
import { formatDateT } from '../../helpers';

const ManageNurse = () => {
  const tableRef = useRef(null);
  const [nurses, setNurses] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchNurses = async () => {
      try {
        const nurseData = await getNurseList();
        setNurses(nurseData);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching nurses:', error);
        setIsLoaded(true);
      }
    };

    fetchNurses();
  }, []);

  useEffect(() => {
    if (tableRef.current && !$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        stripeClasses: ['odd', 'even'],
      });
    }
  }, [nurses]);

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Nurse List</h2>
            <Link to="/admin/add-nurse" className="add-nurse-icon">
              <i className="ri-user-add-line"></i>
            </Link>
          </div>
        </div>
      </header>

      <section className="section-b-space">
        <div className="custom-container">
          {!isLoaded ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
              <Loader isNav={true} />
            </div>
          ) : (
            <table ref={tableRef} id="example" className="display" style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Nurse Profile</th>
                  <th>Last Serviced</th>
                  <th>Upcoming Service</th>
                  <th>Total Serviced</th>
                </tr>
              </thead>
              <tbody>
                {nurses.map((nurse) => (
                  <tr key={nurse.id}>
                    <td>
                      <Link to={`/admin/edit-nurse/${nurse.id}`}>{nurse.name}</Link>
                      <br />
                      {nurse.id}
                    </td>
                    <td>
                      {nurse.last_service ? (
                        <>
                          <Link to={`/admin/appointment/${nurse.last_service.appt_id}`}>Appt ID: #{nurse.last_service.appt_id}</Link>
                          <br />
                          {formatDateT(nurse.last_service.date).toLocaleString()}
                        </>
                      ) : (
                        'No services'
                      )}
                    </td>
                    <td>
                      {nurse.upcoming_service ? (
                        <>
                          <Link to={`/admin/appointment/${nurse.upcoming_service.appt_id}`}>Appt ID: #{nurse.upcoming_service.appt_id}</Link>
                          <br />
                          {formatDateT(nurse.upcoming_service.date).toLocaleString()}
                        </>
                      ) : (
                        'No upcoming services'
                      )}
                    </td>
                    <td>{nurse.total_service || 0}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </section>

      <section className="panel-space"></section>
    </div>
  );
};

export default ManageNurse;
