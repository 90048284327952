import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { adminLogin } from '../../api/auth'; // Adjust this import path as needed

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [encryption, setEncryption] = useState(''); // Added state for encryption
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (encryption.toUpperCase() !== 'SHA256') {
      setError('Invalid encryption method. Please use SHA256.');
      return;
    }

    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await adminLogin(username, password, encryption.toUpperCase());
      if (response.status === 200) {
        Swal.close();
        navigate('/admin/home');
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Success',
        //   text: 'Login successful!',
        //   confirmButtonColor: '#008893',
        // }).then(() => {
        //   navigate('/admin/home');
        // });
      } else {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: response.message || 'Login failed. Please try again.',
          confirmButtonColor: '#008893',
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Login Error',
        text: 'An error occurred. Please try again.',
        confirmButtonColor: '#008893',
      });
    }
  };

  return (
    <section className="section-b-space pt-0">
      <img
        className="img-fluid login-img restaurant-login-img auth-img"
        src="/assets/images/login-screen.png"
        alt="Clinics"
      />

      <div className="custom-container">
        <form className="auth-form mt-4" onSubmit={handleSubmit}>
          <h2>Welcome back boss!</h2>

          {error && <div className="alert alert-danger">{error}</div>}

          <div className="form-group mt-4">
            <label className="form-label fw-semibold dark-text" htmlFor="username">TAID</label>
            <div className="d-flex gap-3">
              <div className="form-input dark-border-gradient">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  placeholder="P0001"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-group mt-4">
            <label className="form-label fw-semibold dark-text" htmlFor="encryption">Encryption</label>
            <div className="d-flex gap-3">
              <div className="form-input dark-border-gradient">
                <input 
                  type="password" 
                  className="form-control" 
                  id="encryption"
                  placeholder="*****"
                  value={encryption}
                  onChange={(e) => setEncryption(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-group mt-4">
            <label className="form-label fw-semibold dark-text" htmlFor="password">Password</label>
            <div className="d-flex gap-3">
              <div className="form-input dark-border-gradient">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          
          <button type="submit" className="btn theme-btn w-100 mt-4">Login</button>
        </form>
        <p className="text-center">
          By continuing, you agree to our Terms of Service, Privacy Policy, and Content Policy.
        </p>
      </div>
    </section>
  );
};

export default AdminLogin;
