import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { updateNurse, getNurseProfile } from '../../api/admin';
import Loader from '../../components/Loader';
import Swal from 'sweetalert2';

const EditNurse = () => {
  const { nurseId } = useParams();
  const navigate = useNavigate();
  const [nurseName, setNurseName] = useState('');
  const [username, setUsername] = useState('');
  const [status, setStatus] = useState('');
  const [totalLeaves, setTotalLeaves] = useState('');
  const [dateJoined, setDateJoined] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchNurse = async () => {
      try {
        const nurse = await getNurseProfile(nurseId);
        setNurseName(nurse.name);
        setUsername(nurse.employee_code);
        setStatus(nurse.status);
        setTotalLeaves(nurse.total_leaves);
        setDateJoined(nurse.date_joined.toDate().toISOString().split('T')[0]);
        setIsLoaded(true);
      } catch (error) {
        console.error('Error fetching nurse:', error);
        setIsLoaded(true);
      }
    };

    fetchNurse();
  }, [nurseId]);

  const handleNurseNameChange = (event) => {
    setNurseName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTotalLeavesChange = (event) => {
    setTotalLeaves(event.target.value);
  };

  const handleDateJoinedChange = (event) => {
    setDateJoined(event.target.value);
  };

  const handleUpdateNurse = async (e) => {
    e.preventDefault();

    // Validation
    if (!nurseName || !username || !status || !totalLeaves || !dateJoined) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'All fields are required.',
        confirmButtonColor: '#008893',
      });
      return;
    }

    try {
      await updateNurse(nurseId, { 
        name: nurseName,
        status: status,
        total_leaves: parseInt(totalLeaves, 10),
        date_joined: new Date(dateJoined)
      });
      Swal.fire({
        icon: 'success',
        title: 'Nurse Updated',
        text: 'The nurse details have been updated successfully.',
        confirmButtonColor: '#008893'
      }).then(() => {
        navigate('/admin/nurse-list');
      });
    } catch (error) {
      console.error('Error updating nurse:', error);
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'There was an error updating the nurse. Please try again later.',
        confirmButtonColor: '#008893'
      });
    }
  };

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/nurse-list">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Edit Nurse</h2>
          </div>
        </div>
      </header>

      <section className="section-b-space profile-lock">
        <div className="custom-container">
          {!isLoaded ? (
            <Loader isNav={true} />
          ) : (
            <form className="auth-form profile-form" onSubmit={handleUpdateNurse}>
              <div className="form-group">
                <div className="form-group mb-3">
                  <label className="form-label fw-semibold">Nurse Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={nurseName}
                    onChange={handleNurseNameChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Username</label>
                <div className="form-input mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={username}
                    onChange={handleUsernameChange}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Status</label>
                <div className="form-input mb-3">
                  <select name="status" className="form-control" value={status} onChange={handleStatusChange}>
                    <option>Active</option>
                    <option>Suspend</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Total Leaves</label>
                <div className="form-input mb-3">
                  <input
                    type="number"
                    className="form-control"
                    value={totalLeaves}
                    onChange={handleTotalLeavesChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label fw-semibold">Date Joined</label>
                <div className="form-input mb-3">
                  <input
                    type="date"
                    className="form-control"
                    value={dateJoined}
                    onChange={handleDateJoinedChange}
                  />
                </div>
              </div>
              <button type="submit" className="btn theme-btn save-address w-100">
                Save
              </button>
            </form>
          )}
        </div>
      </section>
    </div>
  );
};

export default EditNurse;
