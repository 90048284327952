import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import AdminNavbar from '../../components/AdminNavbar';
import Loader from '../../components/Loader';
import { startOfWeek, startOfMonth, startOfYear } from 'date-fns';

const AdminHome = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [dashboard, setDashboard] = useState({
    total_records: 0,
    current_week_records: 0,
    current_month_records: 0,
    current_year_records: 0,
    today_records: 0,
    ongoing_records: 0,
    upcoming_records: 0,
    completed_records: 0,
  });

  useEffect(() => {
    const q = query(collection(db, 'appointments'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const allAppointments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(allAppointments);
      filterAppointments(allAppointments);
      setIsLoaded(true);
    });

    return () => unsubscribe();
  }, []);

  const filterAppointments = (appointments) => {
    const today = new Date();
    const startOfWeekDate = startOfWeek(today);
    const startOfMonthDate = startOfMonth(today);
    const startOfYearDate = startOfYear(today);

    const dashboardData = {
      total_records: appointments.length,
      current_week_records: appointments.filter(app => app.appt_date.toDate() >= startOfWeekDate).length,
      current_month_records: appointments.filter(app => app.appt_date.toDate() >= startOfMonthDate).length,
      current_year_records: appointments.filter(app => app.appt_date.toDate() >= startOfYearDate).length,
      today_records: appointments.filter(app => app.appt_date.toDate().toDateString() === today.toDateString()).length,
      ongoing_records: appointments.filter(app => app.status === 'Ongoing').length,
      upcoming_records: appointments.filter(app => app.status === 'Upcoming').length,
      completed_records: appointments.filter(app => app.status === 'Completed').length,
    };

    setDashboard(dashboardData);
  };

  const getValue = (value) => (value == null ? 0 : value);

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header">
            <Link to="#" style={{ display: 'none' }}></Link>
            <div className="head-content">
              <div className="text-center">
                <h2>BACKLINERS - DIRECTOR</h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isLoaded ? (
        !dashboard || Object.keys(dashboard).length === 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
            <h4>No Data</h4>
          </div>
        ) : (
          <section className="section-b-space">
            <div className="custom-container">
              <div className="total-sales-background mt-3">
                <div className="earning-card-details">
                  <div className="w-100">
                    <div className="d-flex align-items-center">
                      <img className="img-fluid icon" src="/assets/images/svg/nurses_blue.svg" alt="wallet-money" />
                      <div className="ps-2">
                        <h5 className="dark-text">Total Appointments</h5>
                      </div>
                      <h2 className="ms-auto">{getValue(dashboard.total_records)} cases</h2>
                    </div>
                  </div>
                </div>

                <ul className="sale-timing-list">
                  <li className="w-100">
                    <div className="sale-timing-box">
                      <h6 className="fw-normal dark-text">This Week</h6>
                      <h3 className="fw-semibold theme-color">{getValue(dashboard.current_week_records)} cases</h3>
                    </div>
                  </li>
                  <li className="w-100">
                    <div className="sale-timing-box">
                      <h6 className="fw-normal dark-text">This Month</h6>
                      <h3 className="fw-semibold theme-color">{getValue(dashboard.current_month_records)} cases</h3>
                    </div>
                  </li>
                  <li className="w-100">
                    <div className="sale-timing-box">
                      <h6 className="fw-normal dark-text">This Year</h6>
                      <h3 className="fw-semibold theme-color">{getValue(dashboard.current_year_records)} cases</h3>
                    </div>
                  </li>
                </ul>
              </div>

              <ul className="profile-details-list order-list restaurant-order-list mt-4 mb-0">
                <li className="w-100">
                  <img className="img-fluid icon ms-auto mb-0" src="/assets/images/svg/hearttoken_white.svg" alt="box" />
                  <h2>{getValue(dashboard.today_records)}</h2>
                  <h6>Today</h6>
                </li>
                <li className="w-100">
                  <img className="img-fluid icon ms-auto mb-0" src="/assets/images/svg/hearttoken_white.svg" alt="box" />
                  <h2>{getValue(dashboard.ongoing_records)}</h2>
                  <h6>Ongoing</h6>
                </li>
                <li className="w-100">
                  <img className="img-fluid icon ms-auto mb-0" src="/assets/images/svg/hearttoken_white.svg" alt="box" />
                  <h2>{getValue(dashboard.upcoming_records)}</h2>
                  <h6>Upcoming</h6>
                </li>
                <li className="w-100">
                  <img className="img-fluid icon ms-auto mb-0" src="/assets/images/svg/hearttoken_white.svg" alt="box" />
                  <h2>{getValue(dashboard.completed_records)}</h2>
                  <h6>Completed</h6>
                </li>
              </ul>
            </div>
          </section>
        )
      ) : (
        <Loader isNav={true} />
      )}

      <AdminNavbar activeLinks={{ home: true, appointment: false, profile: false }} />

      <section className="panel-space"></section>
    </div>
  );
};

export default AdminHome;
