import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import $ from 'jquery';
import Loader from '../../components/Loader';

const InquiryList = () => {
  const tableRef = useRef(null);
  const [appointments, setAppointments] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const q = query(collection(db, 'appointments'), where('status', '==', 'Pending'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const allAppointments = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAppointments(allAppointments);
      setIsLoaded(true);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isLoaded && tableRef.current) {
      $(tableRef.current).DataTable().destroy();
      $(tableRef.current).DataTable({
        data: appointments,
        columns: [
          { title: "Patient Profile", data: null, render: function(data, type, row) {
              return `${data.patient_name}<br />${data.patient_nric}`;
            }
          },
          { title: "Remark", data: "services" },
          { title: "Date", data: null, render: function(data, type, row) {
              return new Date(data.appt_date.seconds * 1000).toLocaleString();
            }
          },
          { title: "Action", data: null, render: function(data, type, row) {
              return `<a href="/admin/edit-appointment/${data.id}"><small>Manage</small></a>`;
            }
          }
        ],
        stripeClasses: ['odd', 'even'],
      });
    }
  }, [isLoaded, appointments]);

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/admin/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Inquiry List</h2>
          </div>
        </div>
      </header>

      {
        isLoaded ?
        <section className="section-b-space">
        <div className="custom-container">
          <table ref={tableRef} id="example" className="display" style={{ width: '100%' }}></table>
        </div>
      </section> :
      <Loader nav={false}/>
      }

      <section className="panel-space"></section>
    </div>
  );
};

export default InquiryList;
