import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { nurseRequestLeave } from '../../api/leave';
import { getNurseProfile } from '../../api/profile';
import Swal from 'sweetalert2';

const NurseLeave = () => {
  const [date, setDate] = useState('');
  const [reason, setReason] = useState('');
  const [nurseProfile, setNurseProfile] = useState(null); // State to store nurse profile
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNurseProfile = async () => {
      try {
        const profile = await getNurseProfile();
        setNurseProfile(profile);
      } catch (error) {
        console.error('Error fetching nurse profile:', error);
      }
    };

    fetchNurseProfile();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: 'Requesting...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (!date) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select a date for the leave application.',
        confirmButtonColor: '#008893',
      });
      return;
    }
    try {
      const profile = {
        nurse_id: nurseProfile.employee_code,
        nurse_name: nurseProfile.name,
      }
      const data = await nurseRequestLeave(date, reason, profile);
      Swal.close();
      Swal.fire({
        icon: 'success',
        title: 'Leave Application',
        text: 'Your leave request has been submitted successfully.',
        confirmButtonColor: '#008893',
      });
      navigate('/nurse/profile');
    } catch (error) {
      console.error('Error requesting leave:', error);
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error submitting your leave request.',
        confirmButtonColor: '#008893',
      });
    }
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
      <header className="section-t-space">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/nurse/profile">
              <i className="ri-arrow-left-s-line"></i>
            </Link>
            <h2>Apply Leave</h2>
          </div>
        </div>
      </header>
      
      <section className="section-b-space profile-lock">
        <div className="custom-container">
          <form className="auth-form profile-form" target="_blank" onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-group mb-3">
                <label className="form-label fw-semibold">Date of application</label>
                <input 
                  type="date" 
                  className="form-control shadow-none"
                  min={today} // Set the minimum date to today
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="form-label fw-semibold">Reason (Optional)</label>
              <div className="form-input mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Because I need to go travel"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </div>
            </div>
            <button type="submit" className="btn theme-btn save-address w-100">Submit</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default NurseLeave;
